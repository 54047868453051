<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">I. Life At School</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel">What is the Student's general Opinion of School?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="What_is_student_general_opinion_of_school">
                    <mat-radio-button value="Positive">Positive</mat-radio-button>
                    <mat-radio-button value="Negative">Negative</mat-radio-button>
                    <mat-radio-button value="Neutral">Neutral</mat-radio-button>
                  </mat-radio-group>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">The Student goes to School</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="The_student_goes_to_school">
                    <mat-radio-button value="Willingly">Willingly</mat-radio-button>
                    <mat-radio-button value="Unwillingly">Unwillingly</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">How would you rate the Student's overall Attendance at School?</mat-label>
                  <div class="col-md-6 m-t-30 m-l-12">
                    <div class="sliderLabel">
                    <span>{{ sliderLabel }}</span>
                    </div>
                    <mat-slider class="mat-slider" [min]="0" [max]="10" discrete="true" (input)="updateSliderValue($event)">
                      <input matSliderThumb formControlName="How_would_you_rate_student_overall_attendance_at_school">
                    </mat-slider>
                    
                    
                  </div>
                  <div class="number-container row col-md-12 m-t-10 m-l-12">
                    <div *ngFor="let number of sliderScaleValues" class="subh">{{ number }}</div>
                  </div>
                  <div class="row d-flex">
                    <div class="col-md-6 m-t-20">
                      <mat-label class="m-t-64 m-l-40">Very Poor</mat-label>
                    </div>
                    <div class="col-md-6 m-t-20">
                      <mat-label class="m-t-64 m-l-40">Excellent</mat-label>
                    </div>
                  </div>
                </div>
                


              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
