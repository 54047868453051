import { Component,Output, EventEmitter,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
// import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {  OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NativeDateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormService } from '../form/form.service';
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {
  mobileQuery: MediaQueryList = this.mediaMatcher.matchMedia('(max-width: 600px)');
  showMobileIcon = this.mobileQuery.matches;
  private _mobileQueryListener: () => void;
  displayName='userRegistration';
  formData:any= {studentFirstName:'',
  studentDOB:'',
  mothersFirstName:'',
  emailId:'',}
  verificationCode='';
  otpValue: string[] = ['', '', '', ''];
  invalidDetailsMessage: any;
  successmsg: any;
  result: any;
  oneTime:any;
  userId: any;
  resendButtonDisabled = false;
  countdownSeconds: number = 0;
  countdownInterval: any;
  otpSentMessage:any;
  otpExpired: boolean = false;
  showResendError: boolean = false;
  invalidDetailsMessages:any;
  mobile: any;
  UIN: any;

  constructor( private routes:Router ,private formservice:FormService, private breakpointObserver: BreakpointObserver,private fb: FormBuilder,private mediaMatcher: MediaMatcher,private renderer: Renderer2,){
    console.log('Component initialized');
    this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
        console.log('Small screen detected');
      } else {
        console.log('Larger screen detected');
      }
    });
    this._mobileQueryListener = () => (this.showMobileIcon = this.mobileQuery.matches);
    this.mobileQuery.addListener(this._mobileQueryListener);
    breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
        }
      }
    });
}
goBack(){
  this.routes.navigate(['/login'])
}
  onOtpInputChange(index: number, nextInput: HTMLInputElement | null, prevInput: HTMLInputElement | null): void {
    this.otpValue[index] = this.otpValue[index].replace(/[^0-9]/g, '');
    if (this.otpValue[index] === '' && prevInput) {
      prevInput.focus();
    } else if (this.otpValue[index] !== '' && nextInput) {
      nextInput.focus();
    }
  }
  onKeyDown(event: KeyboardEvent, prevInput: HTMLInputElement | null, nextInput: HTMLInputElement | null): void {
    if (event.key === 'ArrowLeft' && prevInput) {
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && nextInput) {
      nextInput.focus();
    }
  }


  otp(){
    this.displayName='otp';
  }
  // terms(){
  //   this.displayName='terms';
  // }
  user(){
    this.routes.navigate(['/login'])
  }


  otpSubmit() {
    const storedDetails = localStorage.getItem('details');
    const oneTime = this.otpValue.join('');
    console.log("oneTime",oneTime);
    let otpData; // Declare formData outside the if block

    if (storedDetails) {
      const details = JSON.parse(storedDetails);

      // Now 'details' contains the retrieved data
      console.log(details.Student_DOB, 'login');

      // Use 'details' as needed in your component logic

      // For example, you might want to use the retrieved details in a form
      const studentOtp=({
        Student_FirstName: details.Student_FirstName,
        Mothers_FullName: details.Mothers_FullName,
        Email: details.Email,
        Student_DOB: details.Student_DOB, // Assuming details.formattedDOB is correct
        Otp: oneTime,
      });
      console.log(studentOtp, 'swaroop');
      this.formservice.otpVerfication(studentOtp).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            // Handle success scenario
            this.successmsg = 'Verification successful';
            this.invalidDetailsMessage = '';
            this.displayName = 'terms';
            this.userId = data.Result?.InfoseekId;
            const userId = this.userId;
            this.routes.navigate(['/terms']);
            localStorage.setItem('userId', userId.toString());
            const InfoseekMasterId = data.InfoseekMasterId;
            const userDOB = data.Result?.Student_DOB;
            if (InfoseekMasterId) {
              localStorage.setItem('InfoseekMasterId', InfoseekMasterId.toString());
            }
            if (userDOB) {
              localStorage.setItem('userDOB', userDOB.toString());
            }
          } else if (data['Status'] === 400) {
            // Handle error scenario
            this.successmsg = '';
            this.invalidDetailsMessage = data['Message'];
          }
        },
        (error: any) => {
          // Handle error scenario
          this.successmsg = '';
          this.invalidDetailsMessage = 'Invalid OTP or Expired';
          console.error('Error posting data:', error);
        }
      );
    }




  }


    resetOtpValues() {
      this.otpValue = ['', '', '', ''];
    }

    onResendClick() {
      // Reset relevant variables and messages
      this.otpExpired = false;
      this.showResendError = true;
      this.invalidDetailsMessages = 'OTP sent to your email';
      this.invalidDetailsMessage = false;
      this.resendButtonDisabled = true;
      this.resetOtpValues();
    
      // Start countdown for OTP expiration
      this.startCountdown();
    
      // Call the resend OTP function
      this.resendOtp();
    }
    
    resendOtp() {
      const emailId = this.formData.emailId;
      const storedDetailsString = localStorage.getItem('details');
    
      if (storedDetailsString) {
        const storedDetails = JSON.parse(storedDetailsString);
        if (storedDetails.Student_FirstName && storedDetails.Student_DOB &&
          storedDetails.Mothers_FullName && storedDetails.Email) {
          const detailsToPost = {
            Student_FirstName: storedDetails.Student_FirstName,
            Student_DOB: storedDetails.Student_DOB,
            Mothers_FullName: storedDetails.Mothers_FullName,
            Email: storedDetails.Email
          };
    
          localStorage.setItem('details', JSON.stringify(detailsToPost));
    
          // Call the API to resend OTP
          this.formservice.userVerification(detailsToPost).subscribe(
            (data: any) => {
              if (data['Status'] === 200) {
                this.successmsg = 'Verification successful';
                this.invalidDetailsMessage = '';
                this.displayName = 'otp';
              } else if (data['Status'] === 400) {
                this.successmsg = '';
                this.invalidDetailsMessage = data['Message'];
              } else {
                this.successmsg = '';
                this.invalidDetailsMessage = 'An error occurred';
              }
            },
            (error) => {
              this.successmsg = '';
              this.invalidDetailsMessage = 'Invalid Details';
            }
          );
        }
      }
    }
    
    startCountdown() {
      this.countdownSeconds = 10;
      clearInterval(this.countdownInterval);
      this.countdownInterval = setInterval(() => {
        if (this.countdownSeconds > 0) {
          this.countdownSeconds--;
        } else {
          this.resendButtonDisabled = false;
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    }
    closeErrorMessage() {
      this.showResendError = false;
    }
    formatCountdownTime(seconds: number): string {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
      return `${formattedMinutes}:${formattedSeconds}`;
    }
    destroyed = new Subject<void>();
    currentScreenSize: any;
    studentForm!: FormGroup;
    ngOnInit() {
      this.studentForm = this.fb.group({
        firstName: ['', [Validators.required]],
        dateOfBirth: ['', [Validators.required]],
        motherFirstName: ['', [Validators.required]],
        Email: ['', [Validators.required]],
      });
      if (window.screen.width <= 1200) { // 768px portrait
        this.showMobileIcon = true;
      }
    }
  // constructor(private fb: FormBuilder){
  // }
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.mobileQuery.removeListener(this._mobileQueryListener);
}
  }
function userId(userId: any, terms: { Terms_and_conditions: boolean; Version: number; Date: string; }) {
  throw new Error('Function not implemented.');
}
