<app-header></app-header>

<div class="blank-layout-container align-items-center justify-content-center">
  <div class="position-relative row w-100 h-100">

    <div class="col-lg-6 col-xl-8 p-0 bg-white bg-light-primary mt-0 start-0 imageblockradius mobile">
      <div class="h-100 align-items-center justify-content-center img-height d-none d-lg-flex image-container ">
        <img class="image" src="assets/images/svgs/otp/otp-screen.png">
      </div>
    </div>

    <!--Form div starts here-->
    
    <div class="col-lg-6 col-xl-4 p-0">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center formheight">
        
        <div class="row justify-content-center w-100">
          <div class="col-lg-9 max-width-form bg-white border-radius-16">
            <!--Mobile icon comes here-->
            
            <p class="textcenter">
              <img *ngIf="mobile" width="50%" class="align-items-center justify-content-center" src="assets/images/logos/ivin-logo.svg">
            </p>
            <!--Mobile icon comes here-->
              <!--Login Form Starts here-->
               
              <mat-card-content>
                <form (ngSubmit)="otpSubmit()">
                  <h4 class="text-center f-w-700 f-s-24 m-t-64 m-b-40">Enter 4 Digits Code</h4>
                  <div class="text-center f-w-400 f-s-14">Enter the 4-digit code sent to your registered email for verification </div>
                  <div class="otp-input m-b-40">
                    <input #input1 type="text" maxlength="1" [(ngModel)]="otpValue[0]" (input)="onOtpInputChange(0, input2, null)" (keydown)="onKeyDown($event, null, input2)" [ngModelOptions]="{ standalone: true }" autofocus/>
                    <input #input2 type="text" maxlength="1" [(ngModel)]="otpValue[1]" (input)="onOtpInputChange(1, input3, input1)" (keydown)="onKeyDown($event, input1, input3)" [ngModelOptions]="{ standalone: true }"/>
                    <input #input3 type="text" maxlength="1" [(ngModel)]="otpValue[2]" (input)="onOtpInputChange(2, input4, input2)" (keydown)="onKeyDown($event, input2, input4)" [ngModelOptions]="{ standalone: true }"/>
                    <input #input4 type="text" maxlength="1" [(ngModel)]="otpValue[3]" (input)="onOtpInputChange(3, null, input3)" (keydown)="onKeyDown($event, input3, null)"
                    [ngModelOptions]="{ standalone: true }"/>
                  </div>
                  <!-- <p class="text-center f-w-600 f-s-14 m-10 p-b-32"> Didn't recieve the code? <span>Resend</span> </p> -->
                  <div class="text-center f-w-400 f-s-14 m-b-54">{{ otpSentMessage || (otpExpired ? "Your OTP has expired. Click on Resend to get a new   ." : "Didn't receive the code?" ) }}  <span [ngStyle]="{'color': resendButtonDisabled ? '#999999' : '#8F1402', 'cursor': resendButtonDisabled ? 'not-allowed' : 'pointer'}" (click)="onResendClick()" [attr.disabled]="resendButtonDisabled ? 'disabled' : null" class="text-primary focus:outline-none">
                    {{ countdownSeconds > 0 ? formatCountdownTime(countdownSeconds) : 'Resend' }}
                  </span> </div>
                  <div *ngIf="invalidDetailsMessage" class="text-center f-w-400 f-s-14 m-b-54 invalid">
                    {{ invalidDetailsMessage }}
                  </div>
                  <mat-card-actions class="d-flex justify-content-center">
                    <button mat-raised-button class="p-l-42 p-r-42 w-50 nextbuttoncolor formbutton">Submit</button>
                  </mat-card-actions>
                </form>
              </mat-card-content>
              <!--Login Ends here-->
          </div>
        </div>
      </div>
    </div>
    <!--Form div ends here-->

  </div>
</div>
