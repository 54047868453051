import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from './form.service';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],

})
export class FormComponent implements OnInit {
  stepForm!: FormGroup;
  activeStep$: number;

  constructor(private formService: FormService) { }

  ngOnInit(): void {
    this.stepForm = this.formService.stepForm;

    this.formService.activeStep$.subscribe(
      step => this.activeStep$ = step
    );

    this.stepForm = this.formService.stepForm;
    this.formService.activeStep$.subscribe(
      step => this.activeStep$ = step
    );
  }


  confirmAndSubmitForm() {
    this.formService.submit();

  }


  nextStep() {
    if ((this.activeStep$ == 1) && (this.stepForm.controls['personalDetails'].pristine) && (!this.stepForm.controls['personalDetails'].touched)) {
      // TO-DO => display error message if step 1 is skipped

      // console.log(this.stepForm.controls['personalDetails'].pristine, !this.stepForm.controls['personalDetails'].touched)

    } else {
      this.formService.goToNextStep(this.activeStep$);
    }



  }
  goBack() {
    this.formService.goBackToPreviousStep(this.activeStep$);
  }


}


