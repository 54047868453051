<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container>
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">K. Family History</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel">Any Illness/ Condition among immediate Members of the Student Family(grandparents, parents, siblings, aunt, uncle)</mat-label>
                  <section>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Acidity/GI ulcers')" (change)="numberssaved($event, 'Acidity/GI ulcers')">Acidity/GI ulcers</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Anemia')" (change)="numberssaved($event, 'Anemia')">Alzheimer's disease</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Anemia')" (change)="numberssaved($event, 'Anemia')">Anemia</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Arthritis')" (change)="numberssaved($event, 'Arthritis')">Arthritis</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Bronchial asthma')" (change)="numberssaved($event, 'Bronchial asthma')">Bronchial asthma</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Bronchitis')" (change)="numberssaved($event, 'Bronchitis')">Bronchitis</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Cancer')" (change)="numberssaved($event, 'Cancer')">Cancer</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Color blindness')" (change)="numberssaved($event, 'Color blindness')">Color blindness</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Chronic heart disease')" (change)="numberssaved($event, 'Chronic heart disease')">Chronic heart disease</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Depression')" (change)="numberssaved($event, 'Depression')">Depression</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Diabetes')" (change)="numberssaved($event, 'Diabetes')">Diabetes</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Epilepsy')" (change)="numberssaved($event, 'Epilepsy')">Epilepsy</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hair loss')" (change)="numberssaved($event, 'Hair loss')">Hair loss</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hemophilia')" (change)="numberssaved($event, 'Hemophilia')">Hemophilia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hepatitis B')" (change)="numberssaved($event, 'Hepatitis B')">Hepatitis B</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hepatitis C')" (change)="numberssaved($event, 'Hepatitis C')">Hepatitis C</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('HIV')" (change)="numberssaved($event, 'HIV')">HIV</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hypertension')" (change)="numberssaved($event, 'Hypertension')">Hypertension</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Mental illness')" (change)="numberssaved($event, 'Mental illness')">Mental illness</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Obesity')" (change)="numberssaved($event, 'Obesity')">Obesity</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Osteoporosis')" (change)="numberssaved($event, 'Osteoporosis')">Osteoporosis</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Smoking')" (change)="numberssaved($event, 'Smoking')">Smoking</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Stroke')" (change)="numberssaved($event, 'Stroke')">Stroke</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Thalassemia')" (change)="numberssaved($event, 'Thalassemia')">Thalassemia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Tuberculosis')" (change)="numberssaved($event, 'Tuberculosis')">Tuberculosis</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Varicose veins')" (change)="numberssaved($event, 'Varicose veins')">Varicose veins</mat-checkbox></div>
                      <mat-checkbox [checked]="isCheckboxChecked('Other')" class="col-md-4" (change)="numberssaved($event, 'Other')">Other</mat-checkbox>

                    </div>
                   
                  <div class="row" *ngIf="showOtherInput">
                    <div class="col-md-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="If others, type here"  formControlName="Any_students_family_Other">
                        <mat-error class="error-message" *ngIf="showOtherInput && stepForm.get('Any_students_family_Other').invalid && stepForm.get('Any_students_family_Other')">Other activity is required.</mat-error>

                      </mat-form-field>
                    </div>
                    </div>

                  </section>
                </div>

              </div>
            </div>
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>



