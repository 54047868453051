import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss'],
  providers: [FormGroupDirective]  // Add this line if needed
})
export class StepFourComponent implements OnInit {
  @Input() stepForm!: FormGroup;
  userId: string;
  PrimaryContact: boolean = false;
  SecondayContact: boolean = false;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  maxChars = 100;
  nextButtonClicked = false;
  showSecondaryFields: boolean = false;

  constructor(
    private formservice: FormService,
    private fb: FormBuilder,
    private routes: Router
  ) {}

  PrimaryContactchange(event: any) {
    this.PrimaryContact = event.value === 'Other';

    // If the input field is not shown, clear the value
    if (!this.PrimaryContact) {
      this.stepForm.get('Primary_Contact_Belongs_To_Other').setValue('');
    }
  }


  SecondayContactChange(event: any) {
    this.SecondayContact = event.value === 'Other';

    // If the input field is not shown, clear the value
    if (!this.SecondayContact) {
      this.stepForm.get('Secondary_Contact_Belongs_To_Other').setValue('');
    }
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "fghjk");

    // Initialize the FormGroup before using it
    this.stepForm = this.fb.group({
      Primary_Contact: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      Primary_Contact_Belongs_To: ['', Validators.required],
      Primary_Contact_Belongs_To_Other: ['', Validators.required],
      Primary_Contact_Full_Name: ['', Validators.required],
      Secondary_Contact:['', [Validators.required, Validators.pattern('[0-9]*')]],
      Secondary_Contact_Belongs_To: ['', Validators.required],
      Secondary_Contact_Belongs_To_Other: ['', Validators.required],
      Secondary_Contact_Full_Name: ['', Validators.required],
      Family_Doctor_Name: ['', Validators.required],
      Doctor_Contact_Number:[Validators.required, Validators.pattern('[0-9]*')],
    });

  
    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log(this.studentPhotofilename, "image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        this.stepForm.patchValue({
          Primary_Contact: InfoseekMasterId['Primary_Contact'],
          Primary_Contact_Belongs_To: InfoseekMasterId['Primary_Contact_Belongs_To'],
          Primary_Contact_Belongs_To_Other: InfoseekMasterId['Primary_Contact_Belongs_To_Other'],
          Primary_Contact_Full_Name: InfoseekMasterId['Primary_Contact_Full_Name'],
          Secondary_Contact: InfoseekMasterId['Secondary_Contact'],
          Secondary_Contact_Belongs_To: InfoseekMasterId['Secondary_Contact_Belongs_To'],
          Secondary_Contact_Belongs_To_Other: InfoseekMasterId['Secondary_Contact_Belongs_To_Other'],
          Secondary_Contact_Full_Name: InfoseekMasterId['Secondary_Contact_Full_Name'],
          Family_Doctor_Name: InfoseekMasterId['Family_Doctor_Name'],
          Doctor_Contact_Number: InfoseekMasterId['Doctor_Contact_Number'],
        });
        console.log("Form Values After Patching:", this.stepForm.value);
        console.log(InfoseekMasterId,"InfoseekMasterId")
      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );
  }

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }

  // get isValidMobileNumber(): boolean {
  //   return this.stepForm.get('Primary_Contact')?.valid || false;
  // }

  onSubmit() {
    if (this.stepForm.get('Primary_Contact')?.valid && this.stepForm.get('Primary_Contact_Belongs_To')?.valid )  {

    const sectionD = {
      Primary_Contact: this.stepForm.get('Primary_Contact')?.value || null,
      Primary_Contact_Belongs_To: this.stepForm.get('Primary_Contact_Belongs_To')?.value || null,
      Primary_Contact_Belongs_To_Other: this.stepForm.get('Primary_Contact_Belongs_To_Other')?.value || null,
      Primary_Contact_Full_Name: this.stepForm.get('Primary_Contact_Full_Name')?.value || null,
      Secondary_Contact: this.stepForm.get('Secondary_Contact')?.value || null,
      Secondary_Contact_Belongs_To: this.stepForm.get('Secondary_Contact_Belongs_To')?.value || null,
      Secondary_Contact_Belongs_To_Other: this.stepForm.get('Secondary_Contact_Belongs_To_Other')?.value,
      Secondary_Contact_Full_Name: this.stepForm.get('Secondary_Contact_Full_Name')?.value || null,
      Family_Doctor_Name: this.stepForm.get('Family_Doctor_Name')?.value || null,
      Doctor_Contact_Number: this.stepForm.get('Doctor_Contact_Number')?.value ||null,
    };

    console.log(sectionD, "sectionA");

    const userId = localStorage.getItem('userId');
    const formData = new FormData();

    // Append form values to formData

    this.formservice.updateUserRegD(userId, sectionD).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log('User data updated successfully');
          // this.routes.navigate(['/stepfive'])
          this.nextButtonClick();
        }
      },
      (error) => {
        console.error('Error updating user data:', error);
      }
    );
  }
}

  showTextBox: boolean = false;

  showOptions(divNumber: number): void {
    this.showTextBox = divNumber === 2;
  }

  onNextButtonClick() {
    

    this.nextButtonClicked = true;
  }
  changePlan() {
    this.formservice.goBackToPreviousStep(3);
  }

  goToPrevious() {
    this.routes.navigate(['/stepthree']);
  }
}
