<!--Header Block-->
<app-header></app-header>

<div class="blank-layout-container align-items-center justify-content-center">
  <div class="position-relative row w-100 h-100 ">

    <div class="col-lg-6 col-xl-8 p-0 bg-white bg-light-primary mt-0 start-0 imageblockradius mobile">
      <div class="h-100 align-items-center justify-content-center img-height d-none d-lg-flex image-container ">
        <img class="image" src="assets/images/svgs/login/login-screen.svg">
      </div>
    </div>

    <!-- <div class="col-lg-6 col-xl-8 p-0 mt-0 start-0 imageblockradius mobile justify-content-center d-flex">
      <div class="h-100 align-items-center justify-content-center img-height d-none d-lg-flex image-container ">
        <img class="m-l-40 w-50" src="/assets/images/logos/nivish-logo-marron.png">
      </div>
    </div> -->

    <!--Form div starts here-->
    <div class="col-lg-6 col-xl-4 p-0">
      <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center formheight">
        <div class="row justify-content-center w-100">
          <div class="col-lg-9 max-width-form bg-white formborderradius">
            <!--Mobile icon comes here-->
            <p class="textcenter">
              <img *ngIf="mobile" width="50%" class="align-items-center justify-content-center" src="assets/images/logos/ivin-logo.svg">
            </p>
            <!--Mobile icon comes here-->
            <div>
              <h4 class="text-center f-w-700 f-s-24 m-t-32 m-b-32">Infoseek</h4>
              <h4 class="text-center fontcolor f-w-700 f-s-16 m-t-18 m-b-32">Student’s Baseline Health Record</h4>
              <!--Login Form Starts here-->
              <div *ngIf="invalidDetailsMessage" class="text-center f-w-400 f-s-14 m-b-54 invalid">
                {{ invalidDetailsMessage }}
              </div>
              <mat-card-content>
                <form (ngSubmit)="onSubmit()" [formGroup]="studentForm" #verificationForm="ngForm">
                  <mat-form-field appearance="outline" class="w-100 mx-auto p-l-48 p-r-48" color="primary">
                    <input type="text" matInput formControlName="firstName" placeholder="Student First Name">
                    <mat-error *ngIf="studentForm.get('firstName')?.hasError('required')">First name is required</mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field appearance="outline" color="primary">
                    <input matInput  [matDatepicker]="picker" [max]="today" formControlName="dateOfBirth" [value]="formatDate(studentForm.get('dateOfBirth').value)"
                           placeholder="{{ formatDate(studentForm.get('dateOfBirth').value) || 'dd-mm-yyyy' }}" 
                           (focus)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="studentForm.get('dateOfBirth')?.hasError('required')">Date of birth is required</mat-error>
                  </mat-form-field> -->
                  <mat-form-field appearance="outline" class="w-100 mx-auto p-l-48 p-r-48" >
                    <input matInput [matDatepicker]="startPicker" placeholder="DD/MMM/YYYY" formControlName="dateOfBirth">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                    <mat-error *ngIf="studentForm.get('dateOfBirth')?.hasError('required')">Date of birth is required</mat-error>

                </mat-form-field>
                  
                  <mat-form-field appearance="outline" class="w-100 mx-auto p-l-48 p-r-48" color="primary">
                    <input type="text" matInput formControlName="motherFirstName" placeholder="Mother's Name">
                    <mat-error *ngIf="studentForm.get('motherFirstName')?.hasError('required')">Mother First Name required</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100 mx-auto p-l-48 p-r-48 " color="primary">
                    <input matInput type="text" formControlName="Email" placeholder="Email">
                    <mat-error *ngIf="studentForm.get('Email')?.hasError('required')">Enter your Email required</mat-error>
                  </mat-form-field >
                  <mat-card-actions class="d-flex justify-content-center">
                    <button mat-raised-button color="primary" class="p-l-42 p-r-42 nextbuttoncolor formbutton">Verify</button>
                  </mat-card-actions>
                 
                </form>
              </mat-card-content>
              <!--Login Ends here-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Form div ends here-->

  </div>
</div>
