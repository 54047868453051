import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../../form/form.service';

@Component({
  selector: 'app-previousbutton',
  templateUrl: './previousbutton.component.html',
  styleUrls: ['./previousbutton.component.scss']
})
export class PreviousbuttonComponent {

  constructor(
    private routes:Router,
    private formservice: FormService ,
  ){}

  previousButtonClick(): void {
    this.formservice.decrementActiveStep();
  }
}
