import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-two-health-insurance-information',
  templateUrl: './step-two-health-insurance-information.html',
  styleUrls: ['./step-two-health-insurance-information.scss'],
  providers: [FormGroupDirective]  // Add this line

})

export class StepTwoHealthInsuranceInformationComponent implements OnInit{
  stepForm: FormGroup;
  userId: any;
  @Input() formGroupName!: string;
  activeStep$: number;
  formService: any;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  nextButtonClicked = false;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService,
    private fb: FormBuilder,
    private routes:Router
  ){}

  onNextButtonClick() {
    this.nextButtonClicked = true;
    
  }

  formatDate(date: Date | null): string | null {
    if (date) {
      const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
      const month = date.toLocaleDateString('en-US', { month: 'long' });
      const year = date.getFullYear();
  
      return `${day}-${month}-${year}`;
    } else {
      return null;
    }
  }


  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "fghjk");

    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log(this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        this.stepForm.patchValue({
          Do_you_have_health_insurance: InfoseekMasterId['Do_you_have_health_insurance'] || "No",
          Medical_Aid: InfoseekMasterId['Medical_Aid'],
          Policy_Card: InfoseekMasterId['Policy_Card'],
          Policy_Name: InfoseekMasterId['Policy_Name'],
          startdate: InfoseekMasterId['Expiry_Date'],
        });
        console.log("Form Values After Patching:", this.stepForm.value);
      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );

    this.stepForm = this.fb.group({
      Do_you_have_health_insurance: ['', Validators.required]  ,
      Medical_Aid: ['', Validators.required],
      Policy_Card: ['', Validators.required],
      startdate: ['', Validators.required],
      Policy_Name: ['', Validators.required],

    });
    
  }

  goToPrevious(){
    this.routes.navigate(['/steps'])
  }

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }

  onSubmit() {
    const healthInsurance = this.stepForm.get('Do_you_have_health_insurance')?.value;

    if (healthInsurance === 'No') {
      this.stepForm.patchValue({
        Medical_Aid: null,
        Policy_Card: null,
        Policy_Name:null,
        startdate: null,
      });
    }

    const startdate = this.stepForm.get('startdate')?.value;
    let formattedstartDate = null;

    if (startdate) {
      const dobDate = new Date(startdate);
      const dd = String(dobDate.getDate()).padStart(2, '0');
      const mm = String(dobDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = dobDate.getFullYear();
      formattedstartDate = `${yyyy}-${mm}-${dd}`;
    }

    const sectionA = {
      Do_you_have_health_insurance: this.stepForm.get('Do_you_have_health_insurance')?.value,
      Medical_Aid: this.stepForm.get('Medical_Aid')?.value,
      Policy_Card: this.stepForm.get('Policy_Card')?.value,
      Policy_Name: this.stepForm.get('Policy_Name')?.value,
      Expiry_Date: formattedstartDate,
    };

    console.log(sectionA, "sectionA");

    const userId = localStorage.getItem('userId');
    const formData = new FormData();

    this.formservice.updateUserRegB(userId, sectionA).subscribe(
      (data: any) => {
        console.log('Response from backend:', data);
        if (data['Status'] === 200) {
          console.log('User data updated successfully');
          this.nextButtonClick();
        } else {
          console.error('Error updating user data. Status:', data['Status']);
        }
      },
      (error) => {
        console.error('Error updating user data:', error);
      }
    );
  }
}