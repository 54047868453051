<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">F. Dietary Habits</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel">Usual number of Meals per Day (Eg: Breakfast, Lunch, Dinner)</mat-label>
                  <!-- <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="If others, type here" formControlName="Usual_numberof_mealsday" (input)="sanitizeInput($event)">
                  </mat-form-field> -->
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="Usual_numberof_mealsday" placeholder="Usual number of Meals per Day">
                      <mat-option value="1">1</mat-option>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="3">3</mat-option>
                      <mat-option value="4">4</mat-option>
                      <mat-option value="5+">5+</mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>

                <div class="col-md-6">
                  <mat-label class="formlabel">Usual number of Snacks per Day</mat-label>
               
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-select formControlName="Usual_numberof_snacksday" placeholder="Usual number of Snacks per Day">
                      <mat-option value="1">1</mat-option>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="3">3</mat-option>
                      <mat-option value="4">4</mat-option>
                      <mat-option value="5+">5+</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-label class="formlabel">Average total Fluid intake per Day (in mL)</mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="If others, type here" formControlName="What_is_the_students_average_fluid_intake" (input)="fluidsanitizeInput($event)">
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-label class="formlabel">Type of Meal</mat-label>
                  <mat-radio-group formControlName="Type_of_meal"  (change)="typeofmealchange($event)">
                    <div class="row" >
                      <div class="col-md-4"><mat-radio-button value="Vegetarian">Vegetarian</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Vegetarian with Eggs">Vegetarian with eggs</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Vegetarian with Fish">Vegetarian with Fish</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Non Vegetarian">Non-Vegetarian</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Vegan">Vegan</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Organic">Organic</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4" ><mat-radio-button value="Other">Other</mat-radio-button></div>
                    </div>
                  </mat-radio-group>

                    <div *ngIf="typeofmeal">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName= "Type_of_meal_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                        <mat-error class="error-message" *ngIf="typeofmeal && stepForm.get('Type_of_meal_Other').invalid && stepForm.get('Type_of_meal_Other')">Other activity is required.</mat-error>

                      </mat-form-field>
                    </div>

                      <div *ngIf="!typeofmeal && stepForm.get('Type_of_meal_Other').value">
                        <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName="Type_of_meal_Other"placeholder="Enter Other Information"/>
                        
                      </mat-form-field>
                      </div>
                </div>

                <div class="col-md-12">
                  <mat-label class="formlabel"> Does the Student have Breakfast regularly?<br></mat-label>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Does_the_student_have_breakfast_regularly">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="col-md-12">
                  <mat-label class="formlabel"> Does the Student have regular Meals at School?<br></mat-label>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Does_the_student_have_regular_meal_at_school">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>

                  <div class="m-l-12" *ngIf="stepForm.get('Does_the_student_have_regular_meal_at_school').value === 'Yes'">
                    <mat-label class="formlabel">If Yes, where does the Student mostly get the meal? </mat-label>
                    <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Student_If_Yes_where_does_it_come_from" (change)="ifyeschange($event)">
                      <mat-radio-button value="Home">Home</mat-radio-button>
                      <mat-radio-button value="School">School</mat-radio-button>
                      <mat-radio-button value="Stalls/Hawkers">Stalls/Hawkers</mat-radio-button>
                      <mat-radio-button value="Tuck Shop">Tuck Shop</mat-radio-button>
                      <mat-radio-button value="Other">Other</mat-radio-button>
                    </mat-radio-group>
                  
                    </div>
              
                    <div *ngIf="ifyes">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName= "Student_If_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                        <mat-error class="error-message" *ngIf="ifyes && stepForm.get('Student_If_Other').invalid && stepForm.get('Student_If_Other')">Other activity is required.</mat-error>

                      </mat-form-field>
                    </div>

                      <div *ngIf="!ifyes && stepForm.get('Student_If_Other').value">
                        <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName="Student_If_Other"placeholder="Enter Other Information" />
                      </mat-form-field>
                      </div>
                    <mat-label class="formlabel"> Is the Student intolerant of any Food or Food Group?</mat-label>
                    <section>
                      <div class="row">
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Eggs')" [disabled]="noneChecked" (change)="numberssaved($event, 'Eggs')">Eggs</mat-checkbox>
                        </div>
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Milk')" [disabled]="noneChecked" (change)="numberssaved($event, 'Milk')">Milk</mat-checkbox>
                        </div>
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('None')" (change)="onNoneCheckboxChange($event)">None</mat-checkbox>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Nuts')" [disabled]="noneChecked" (change)="numberssaved($event, 'Nuts')">Nuts</mat-checkbox>
                        </div>
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Sea food')" [disabled]="noneChecked" (change)="numberssaved($event, 'Sea food')">Sea food</mat-checkbox>
                        </div>
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Wheat/Gluten')" [disabled]="noneChecked" (change)="numberssaved($event, 'Wheat/Gluten')">Wheat/Gluten</mat-checkbox>
                        </div>
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Other')" [disabled]="noneChecked" (change)="numberssaved($event, 'Other')">Other</mat-checkbox>
                        </div>
                      </div>
                      
                      <div class="row" *ngIf="showOtherInput">
                        <div class="col-md-12">
                          <mat-form-field appearance="outline" class="w-100">
                            <input matInput placeholder="If others, type here"  formControlName="Food_Group_Other">
                            <mat-error class="error-message" *ngIf="showOtherInput && stepForm.get('Food_Group_Other').invalid && stepForm.get('Food_Group_Other')">Other activity is required.</mat-error>
  
                          </mat-form-field>
                        </div>
                      </div>
                      
                    </section>
                  </div>
              </div>
            </div>
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>

