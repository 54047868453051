import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-eleven-family-history',
  templateUrl: './step-eleven-family-history.component.html',
  styleUrls: ['./step-eleven-family-history.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepElevenFamilyHistoryComponent {

  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  selectedNumbers: any[] = [];
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  showOtherInput: boolean=false;



  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ){}

 ngOnInit(): void {

  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");

  // Fetch additional information based on userId
  this.formservice.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];
      this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

      if (this.studentPhotoImageUrl) {
        const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
        this.studentPhotofilename = filename; // Store filename for display
        console.log( this.studentPhotofilename,"image name");
      } else {
        console.warn('studentPhotoImageUrl is null or undefined.');
        this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
      }
      this.stepForm.patchValue({
        Any_Ongoing_Illnesscondition_membersofthe_students_family: InfoseekMasterId['Any_Ongoing_Illnesscondition_membersofthe_students_family'],
        Any_students_family_Other: InfoseekMasterId['Any_students_family_Other'],




      });
      console.log("Form Values After Patching:", this.stepForm.value);
      this.selectedNumbers = InfoseekMasterId['Any_Ongoing_Illnesscondition_membersofthe_students_family']
      ? InfoseekMasterId['Any_Ongoing_Illnesscondition_membersofthe_students_family'].split(',')
      : [];
      this.toggleOtherInput();

    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );

  this.stepForm = this.fb.group({
    Any_Ongoing_Illnesscondition_membersofthe_students_family: [''],
    Any_students_family_Other: [''],





  });
}
numberssaved(event: any, value: string) {
  console.log('Checkbox changed:', event, value,this.selectedNumbers);

  if (event.checked) {
    // Add the value to the array if the checkbox is checked
    this.selectedNumbers.push(value);
    
  } else {
    // Remove the value from the array if the checkbox is unchecked
    this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
  }
  this.toggleOtherInput();
  console.log('Selected Numbers:', this.selectedNumbers);
}
toggleOtherInput(): void {
  console.log("select");
  const activitiesOtherChecked = this.selectedNumbers.includes('Other');

  if (activitiesOtherChecked) {
    this.showOtherInput = true;
    this.stepForm.get('Any_students_family_Other')?.setValidators(Validators.required);
  } else {
    this.showOtherInput = false;
    this.stepForm.get('Any_students_family_Other')?.setValue(''); // Clear the input field
    this.stepForm.get('Any_students_family_Other')?.clearValidators();
  }
  this.stepForm.get('Any_students_family_Other')?.updateValueAndValidity();
}


isCheckboxChecked(value: string): boolean {
  const formValue = this.stepForm.get('Any_Ongoing_Illnesscondition_membersofthe_students_family')?.value;
  return formValue ? formValue.includes(value) : false;
}

goToPreviou(){
  this.routes.navigate(['/stepnine'])
}

nextButtonClick(): void {
  this.formservice.incrementActiveStep();
}

onSubmit() {
  const sectionD = {
    Any_Ongoing_Illnesscondition_membersofthe_students_family: this.selectedNumbers.join(',')|| null,
    Any_students_family_Other: this.stepForm.get('Any_students_family_Other')?.value|| null,
  };

console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegK(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepetwelve'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}
}

