<div class="row">
  <app-header></app-header>
</div>

<!--Print ID Card Block-->
<div class="justify-content-center d-flex m-t-8 m-b-20 card">
  <div id="downloadIdCard" class="idcard p-8">
    <p style="text-align: center;">
      <img src="/assets/images/logos/logo-maroon.svg">
    </p>
    <div class="row justify-content-center d-flex" id="content" >
      <div class="col-md-12 circle">
        <img [src]="stepForm.get('upload_photo').value" alt="Profile Image" style="width: 40%; ">
      </div>
     
    </div>
    <div class="justify-content-center d-flex">
      <h2 class="uin"> UIN : <strong>{{UIN}}</strong></h2>
    </div>
    
    <div class="qrcode d-flex align-items-center">
      <img class="QRCodeImage" [src]="stepForm.get('qrcode_image').value" alt="QR Code Image" style="width: 30%;">
      <div class="row w-100">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-md-8">
              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="label-fixed-width">Student Name <span>:</span></mat-label>
                <span><strong>{{ stepForm.get('Student_FirstName').value }}</strong></span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="label-fixed-width">Class Name <span>:</span></mat-label>
                <span><strong>{{ stepForm.get('class_name').value }}</strong></span>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-8">
              <div class="d-flex justify-content-between align-items-center">
                <mat-label>DOB <span>:</span></mat-label>
                <span><strong>{{ stepForm.get('Student_DOB').value }}</strong></span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex justify-content-between align-items-center">
                <mat-label class="label-fixed-width">Gender <span>:</span></mat-label>
                <span><strong>{{ stepForm.get('Gender').value }}</strong></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

  </div>
</div>

<!-- <div class="justify-content-center d-flex m-t-8 m-b-20">
  <div id="downloadIdCard" class="bigBox p-8">
    <div>
      <div class="rightBox">
        <div class="rightbox-content">
          <h2> UIN : {{UIN}}</h2>
          <span> DOB : {{ stepForm.get('Student_DOB').value }}</span>
          <span>Gender : {{ stepForm.get('Gender').value }}</span>
        </div>
        <div class="qrcode m-t-40">
        <p style="text-align: center;" class="m-t-40">
          <img [src]="stepForm.get('qrcode_image').value" alt="QR Code Image"  style="width: 50%; margin-top: 20%;">
        </p>
        <p style="text-align: center;margin-top: 16%;">
          <img src="/assets/images/logos/logo-maroon.svg">
        </p>
        </div>
      </div>
    </div>
    <div>
      <p style="text-align: center;">
        <img src="/assets/images/logos/logo-maroon.svg">
      </p>
      <div id="content">
        <div class="circle m-l-20">
          <img [src]="stepForm.get('upload_photo').value" alt="Profile Image" style="width: 50%; ">
        </div>
      </div>
      <div class="leftBox">
        <div class="leftbox-content">
          <h1 class="text-transform-capital boxcontent-h1">
          Student Name : {{ stepForm.get('Student_FirstName').value }}
          </h1>
          <span>Class Name : {{ stepForm.get('class_name').value }}</span>
          <span>Section Name : {{ stepForm.get('section_name').value }}</span>

        </div>
      </div>
    </div>
  </div>
  <div>
    <p>
   
    </p>
  </div>
</div> -->

<!--Print Document Block-->

  <!-- <div style="border: 1px solid" class="row p-64 internalform"> -->

    <!-- <input type="button" value="Download" (click)="captureScreen()" /> -->

    <div id="downloadDocument" style="padding: 0 200px">
    <div class="card">
<div class="col-md-12">
    <div class="col-md-12">
      <h1 class="justify-content-center d-flex marroncolor">InfoSeek - Baseline Health Record</h1>
      <div class="p-r-48 p-l-48">
      </div>
    </div>

    <!--Student Personal Info-->
  
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">A. Student Personal Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field 1 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">The following information is provided by</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('following_information_providedby').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('If_Other').value">
              <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
              <span class="Other">{{ stepForm.get('If_Other').value }}</span>
          </div>

            <!-- Field 2 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Student First Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_FirstName').value }}</span>
            </div>
    
            <!-- Field 3 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Student Middle Name 1</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_MiddleName1').value }}</span>
            </div>
    
            <!-- Field 4 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Student Middle Name 2</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_MiddleName2').value }}</span>
            </div>
    
            <!-- Field 5 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Student Last Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_LastName').value }}</span>
            </div>
    
            <!-- Field 6 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">DOB</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_DOB').value }}</span>
            </div>
    
            <!-- Field 7 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Gender</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Gender').value }}</span>
            </div>
    
            <!-- Field 8 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Blood Group</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Blood_group').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('BloodGroup_Other').value">
              <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
              <span class="Other">{{ stepForm.get('BloodGroup_Other').value }}</span>
          </div>
            <!-- Field 9 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Rh Factor</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Rh_Factor').value }}</span>
            </div>
    
            <!-- Field 10 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Number of Sisters</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Number_Of_Sisters').value }}</span>
            </div>
    
            <!-- Field 11 -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Number of Brothers</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Number_Of_Brothers').value }}</span>
            </div>
    
            <!-- Additional fields for Mother's and Father's names and ethnicity -->
    
            <!-- Mother Information -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Mother First Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Mothers_FirstName').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Mother Middle Name 1</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Mothers_MiddleName1').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Mother Middle Name 2</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Mothers_MiddleName2').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Mother Last Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Mothers_LastName').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center">
              <mat-label class="f-w-600 label-fixed-width">Mother Ethnicity </mat-label><span>:</span>
          </div>
          <div class="col-sm-4">
            <span>{{ stepForm.get('Mothers_Ethnicity').value }}</span>
          </div>
          <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Mothers_Ethnicity_If_Other').value">
            <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
            <span class="Other">{{ stepForm.get('Mothers_Ethnicity_If_Other').value }}</span>
        </div>

            <!-- Father Information -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Father First Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Fathers_FirstName').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Father Middle Name 1</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Fathers_MiddleName1').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Father Middle Name 2</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Fathers_MiddleName2').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Father Last Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Fathers_LastName').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Father Ethnicity</mat-label><span>:</span>
              </div>
            </div>
          
            <div class="col-sm-4">
              <span>{{ stepForm.get('Fathers_Ethnicity').value }}</span>
            </div>
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Fathers_Ethnicity_If_Other').value">
              <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
              <span class="Other">{{ stepForm.get('Fathers_Ethnicity_If_Other').value }}</span>
          </div>
            <!-- Add more fields similarly for the Father Middle Name, Last Name, Ethnicity, etc. -->
    
          </div>
        </div>
      </div>
    </div>
    
    <!--Parent Info-->
    <div class="col-md-12 p-r-64 p-l-64">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">B. Health Insurance Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Does the Student have health insurance? -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Does the Student have health insurance</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Do_you_have_health_insurance').value }}</span>
            </div>
    
            <!-- Field: Health insurance -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Health insurance</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Medical_Aid').value }}</span>
            </div>
    
            <!-- Field: Policy # -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Policy #</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Policy_Card').value }}</span>
            </div>
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Policy Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Policy_Name').value }}</span>
            </div>
            <!-- Field: Policy Expiry Date -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Policy Expiry Date</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Expiry_Date').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    


    <!--Doctor Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">C. Address Information</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Building Name -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Building Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Building_Name').value }}</span>
            </div>
    
            <!-- Field: Apartment/Villa # -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Apartment/Villa #</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Apartment_Villa_No').value }}</span>
            </div>
    
            <!-- Field: Emirates/Street -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Emirates/Street</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Street_No').value }}</span>
            </div>
    
            <!-- Field: Area -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Area</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Area').value }}</span>
            </div>
    
            <!-- Field: City -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">City</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('City').value }}</span>
            </div>
    
            <!-- Field: Country -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Country</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Country').value }}</span>
            </div>
    
            <!-- Field: State -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">State</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('State').value }}</span>
            </div>
    
            <!-- Field: Zip Code -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Zip Code</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Zip_Code').value }}</span>
            </div>
    
            <!-- Field: Emergency Mobile Number Registered With School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Emergency Mobile Number Registered With School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Emergency_MobileNumber_Registered_with_school').value }}</span>
            </div>
    
            <!-- Field: Alternative Mobile Number -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Alternative Mobile Number</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Alternate_MobileNumber').value }}</span>
            </div>
    
            <!-- Field: Belongs To -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Belongs To</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Belongs_To').value }}</span>
            </div>
    
            <!-- Field: Email Registered With School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Email Registered With School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Email_Registered_With_School').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    <!--School Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">D. Emergency Contact</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Primary Contact Belongs to -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Primary Contact Belongs to</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Primary_Contact_Belongs_To').value }}</span>
            </div>
    
            <!-- Field: Other (Primary Contact Belongs to) -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Primary_Contact_Belongs_To_Other').value">
              <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
              <span class="Other">{{ stepForm.get('Primary_Contact_Belongs_To_Other').value }}</span>
          </div>
    
            <!-- Field: Primary Contact # -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Primary Contact #</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Primary_Contact').value }}</span>
            </div>
    
            <!-- Field: Full Name of Primary Contact -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Full Name of Primary Contact</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Primary_Contact_Full_Name').value }}</span>
            </div>
    
            <!-- Field: Secondary Contact -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Secondary Contact</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Secondary_Contact').value }}</span>
            </div>
    
            <!-- Field: Secondary Contact Belongs to -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Secondary Contact Belongs to</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Secondary_Contact_Belongs_To').value }}</span>
            </div>
    
            <!-- Field: Other (Secondary Contact Belongs to) -->
            <div class="col-sm-12 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Secondary_Contact_Belongs_To_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Secondary_Contact_Belongs_To_Other').value }}</span>
            </div>
    
            <!-- Field: Full Name of Secondary Contact -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Full Name of Secondary Contact</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Secondary_Contact_Full_Name').value }}</span>
            </div>
    
            <!-- Field: Family Physician Name -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Family Physician Name</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Family_Doctor_Name').value }}</span>
            </div>
    
            <!-- Field: Physician Contact # -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Physician Contact #</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Doctor_Contact_Number').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    

    <!--Basic Lifestyle Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">E. Basic Life Style</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Recreational Activity -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">What kind of Recreational Activity does the Student enjoy the most</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_typeof_recreational_activity_doesthestudentenjoy').value }}</span>
            </div>
    
            <!-- Field: Enjoyable Activities -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Which of the following Activities does the Student enjoy</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Which_ofthe_following_activities_doesthestudentenjoy').value }}</span>
            </div>
    
            <!-- Field: Other Activities -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Activites_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Activites_Other').value }}</span>
            </div>
    
            <!-- Field: Sports Team Membership -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Is the Student a member of any Sports team at School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Isthe_student_memberofasports_teamatschool').value }}</span>
            </div>
    
            <!-- Field: Sports Team Name -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">If yes, which one</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Isthe_student_memberofasports_teamatschool_Yes').value }}</span>
            </div>
    
            <!-- Field: Pets at Home -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Do you have Pets at home</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Do_you_have_pets_at_home').value }}</span>
            </div>
    
            <!-- Field: Pet Animal -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Pet Animal</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Do_you_have_pets_at_home_Yes_Animal').value }}</span>
            </div>
    
            <!-- Field: Duration of Pet Ownership -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Duration (months)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Do_you_have_pets_at_home_Yes_Duration').value }}</span>
            </div>
    
            <!-- Empty Space for Alignment -->
            <div class="col-sm-4 d-flex align-items-center m-t-8">
              <mat-label></mat-label>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    <!--Dietary Habity Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">F. Dietary Habits</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Usual Number of Meals per Day -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Usual number of Meals per Day (Eg: Breakfast, Lunch, Dinner)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Usual_numberof_mealsday').value }}</span>
            </div>
    
            <!-- Field: Usual Number of Snacks per Day -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Usual number of Snacks per Day</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Usual_numberof_snacksday').value }}</span>
            </div>
    
            <!-- Field: Average Fluid Intake per Day -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Average total Fluid intake per Day (in mL)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_is_the_students_average_fluid_intake').value }}</span>
            </div>
    
            <!-- Field: Type of Meal -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Type of Meal</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Type_of_meal').value }}</span>
            </div>
    
            <!-- Field: Other Type of Meal -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Type_of_meal_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Type_of_meal_Other').value }}</span>
            </div>
    
            <!-- Field: Breakfast Regularly -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Does the Student have Breakfast regularly</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_breakfast_regularly').value }}</span>
            </div>
    
            <!-- Field: Regular Meals at School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Does the Student have regular Meals at School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_regular_meal_at_school').value }}</span>
            </div>
    
            <!-- Field: Where does the Student mostly get the meal -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">If Yes, where does the Student mostly get the meal</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Student_If_Yes_where_does_it_come_from').value }}</span>
            </div>
    
            <!-- Field: Other Source for Meals -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_If_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Student_If_Other').value }}</span>
            </div>
    
            <!-- Field: Food or Food Group Intolerance -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Is the Student intolerant of any Food or Food Group</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Is_the_student_intolerant_to_any_food_group').value }}</span>
            </div>
    
            <!-- Field: Other Food Intolerance -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Food_Group_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Food_Group_Other').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    



    <!--Sleep Pattern & Quality Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">G. Sleep Pattern & Quality</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Fresh and Relaxed After Night's Sleep -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">On an average is the Student fresh and relaxed after Night's sleep</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('On_an_average_isthe_student_freshandrelaxed_night_sleep').value }}</span>
            </div>
    
            <!-- Field: Sleep Related Issues -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Does the Student have any sleep related issues (Tick all that are applicable)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_sleep_related_issues').value }}</span>
            </div>
    
            <!-- Field: Details About Sleep Related Issues -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Please provide details about Student have any sleep related issues</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_sleep_related_issues_Yes').value }}</span>
            </div>
    
            <!-- Field: Other Sleep Related Issues -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Sleep_related_issues_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Sleep_related_issues_Other').value }}</span>
            </div>
    
            <!-- Field: Average Length of Sleep per Night -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">What is the Student's average length of sleep per Night</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_is_the_students_average_length_of_sleep_per_night').value }}</span>
            </div>
    
            <!-- Field: Nap Cycle During the Day -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Nap</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_is_the_students_nap_cycle_during_day_Nap').value }}</span>
            </div>
    
            <!-- Field: Nap Duration -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Duration</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_is_the_students_nap_cycle_during_day_Nap_Duration').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

   

    <!--Personality & Social Interaction Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">H. Personality & Social Interaction</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Social Personality of the Student -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you best describe the Social Personality of the Student</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_describe_the_social_personality_ofthe_student').value }}</span>
            </div>
    
            <!-- Field: Other Social Personality -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Social_personality_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Social_personality_Other').value }}</span>
            </div>
    
            <!-- Field: Irrational Fears or Phobias -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Specify if the Student has any irrational Fears or Phobias</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Specify_if_the_student_has_any_irrational_fears_phobias').value }}</span>
            </div>
    
            <!-- Field: Self Image/Self Worth -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you rate the Student Self Image/Self Worth</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_the_students_self_image_self_worth').value }}</span>
            </div>
    
            <!-- Field: Cooperation in Household Chores -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you rate the Student's Cooperation in various household chores</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_students_cooperation_in_housedhold_chores').value }}</span>
            </div>
    
            <!-- Field: Reaction to Change -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you describe the Student's reaction to Change</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_best_describe_the_student_reaction_to_change').value }}</span>
            </div>
    
            <!-- Field: Other Reaction to Change -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_reaction_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('Student_reaction_Other').value }}</span>
            </div>
    
            <!-- Field: Relationship with Other Students -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you describe the Student's relationship with other Students</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_describe_student_rs_with_other_students').value }}</span>
            </div>
    
            <!-- Field: Other Relationship with Other Students -->
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').value">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Other</mat-label><span>:</span>
              </div>
              <span class="Other">{{ stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').value }}</span>
            </div>
    
            <!-- Field: Significantly Close Friends -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Does the Student have any significantly close Friends</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_significantly_close_friends').value }}</span>
            </div>
    
            <!-- Field: Number of Boys -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How many boys</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('student_close_friends_yes_how_many_boys').value }}</span>
            </div>
    
            <!-- Field: Number of Girls -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How many girls</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('student_close_friends_yes_how_many_girls').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    



    <!--Lite At School Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">I. Life At School</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Goes to School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">The Student goes to School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('The_student_goes_to_school').value }}</span>
            </div>
    
            <!-- Field: Opinion of School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">What is the Student's general Opinion of School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('What_is_student_general_opinion_of_school').value }}</span>
            </div>
    
            <!-- Field: Attendance at School -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you rate the Student's overall Attendance at School</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_student_overall_attendance_at_school').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    <!--General History Info-->
    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">J. General History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Concerns with the Student -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Do you have Concerns with any of the following with respect to the Student? (Tick all that applicable)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Do_you_have_concerns_following_respect_tothe_student').value }}</span>
            </div>
    
            <!-- Field: Overall Physical Health -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">How would you rate Student's overall Physical Health?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('How_would_you_rate_the_student_overall_physical_health').value }}</span>
            </div>
    
            <!-- Field: Dissatisfaction with School Performance -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Is the student's school performance significantly dissatisfied by any of the following?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Isthe_student_significantly_dissatisfied_byany_following').value }}</span>
            </div>
          
              <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Student_dissatisfied_Other').value">
                <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
                <span class="Other">{{ stepForm.get('Student_dissatisfied_Other').value }}</span>
            </div>
    
            <!-- Field: School Performance Affected by -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Is the Student's School performance affected by any following?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Isthe_students_school_performance_affected_by_any_following').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">K. Family History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
    
            <!-- Field: Illness/Condition among Immediate Family Members -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Any Illness/Condition among immediate Members of the Student Family (grandparents, parents, siblings, aunt, uncle)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              
              <span>{{ stepForm.get('Any_Ongoing_Illnesscondition_membersofthe_students_family').value }}</span>
            </div>
    
            <!-- Additional Information (Other) -->
      
            <div class="col-sm-10 d-flex align-items-center m-t-8" *ngIf="stepForm.get('Any_students_family_Other').value">
              <mat-label class="f-w-600 label-fixed-width">Other </mat-label><span>:</span>
              <span class="Other">{{ stepForm.get('Any_students_family_Other').value }}</span>
          </div>
          </div>
        </div>
      </div>
    </div>
    

   

    <div class="col-md-12 p-r-64 p-l-64 m-b-48">
      <h3 class="align-items-center justify-content-center d-flex marroncolor">L. Medical History</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="row m-r-80 m-l-80">
            
            <!-- Significant Medical Issues Currently -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Did the Student have any significant medical issue currently? (Tick all that are applicable)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_Currently').value }}</span>
            </div>
            
            <!-- Details about Current Medical Issues -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Please provide details about Did the Student have any significant medical issue currently?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_Currently_Yes').value }}</span>
            </div>
            
            <!-- Current Medication -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Current Medication, if any:</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Current_Medication').value }}</span>
            </div>
            
            <!-- Significant Medical Issues in the Past -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Did the Student have any significant medical issue in the past? (Tick all that are applicable)</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_In_The_Past').value }}</span>
            </div>
            
            <!-- Details about Past Medical Issues -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Please provide details about Did the Student have any significant medical issue in the past?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Does_the_student_have_any_medicalissue_In_The_Past_Yes').value }}</span>
            </div>
            
            <!-- Past Medication -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Past Medication, if any:</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Past_Medication').value }}</span>
            </div>
            
            <!-- Known Allergies -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">Any known Allergies?</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Any_Known_Allergies').value }}</span>
            </div>
            
            <!-- Details about Allergies -->
            <div class="col-sm-8 d-flex align-items-center m-t-8">
              <div class="d-flex label-container">
                <mat-label class="f-w-600 label-fixed-width">If yes, please provide details about your allergies</mat-label><span>:</span>
              </div>
            </div>
            <div class="col-sm-4 m-t-8">
              <span>{{ stepForm.get('Any_Known_Allergies_yes').value }}</span>
            </div>
    
          </div>
        </div>
      </div>
    </div>
    

    <div class="col-md-12">
      <div class="nextbuttoncolor justify-content-center d-flex" style="height: 50px;">
        <mat-card-content class="m-t-20 whitecolor justify-content-center d-flex">Novel Implementation of a Vision for an Integrated System of Healthcare</mat-card-content>
      </div>
    </div>
</div>
    </div>
    </div>
  <!-- </div> -->

 
<div class="col-md-12 m-t-20 p-b-40">
  <div class="justify-content-center d-flex" style="height: 50px;">
    <button mat-raised-button class="sharebutton m-r-20"><mat-icon>share</mat-icon>Share</button>
    <button mat-raised-button class="downloadbutton" (click)="captureScreen()"><mat-icon>download</mat-icon>Download</button>
  </div>
</div>
    