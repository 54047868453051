<!-- <app-header></app-header>

<ng-container [formGroup]="stepForm" >

  <app-step-student-personal-info *ngIf="activeStep$ == 1" formGroupName="personalDetails" ></app-step-student-personal-info>

</ng-container> -->


<app-header></app-header>

<!-- <div class="row formspace"> -->
  <div class="row">
  <div class="col-lg-4">
    <app-step-tracker-icons class="nav"> </app-step-tracker-icons>
  </div>
  <div class="col-lg-8" style="height: calc(95vh - 200px);">
    <form class="form-padding">
      <ng-container [formGroup]="stepForm" name="stepForm">
        <app-step-student-personal-info *ngIf="activeStep$ == 1" formGroupName="personalDetails" class="w-100"></app-step-student-personal-info>
        <app-step-two-health-insurance-information *ngIf="activeStep$ == 2" formGroupName="planDetails" class="w-100"></app-step-two-health-insurance-information>
        <app-step-three-add-ons *ngIf="activeStep$ == 3" formGroupName="addOnDetails" class="w-100"></app-step-three-add-ons>
        <app-step-four *ngIf="activeStep$ == 4" formGroupName="addOnDetails" class="w-100"></app-step-four>
        <app-step-five-confim *ngIf="activeStep$ == 5" class="w-100"></app-step-five-confim>
        <app-step-six *ngIf="activeStep$ == 6" class="w-100"></app-step-six>
        <app-step-seven-sleep-pattern-quality *ngIf="activeStep$ == 7" class="w-100"></app-step-seven-sleep-pattern-quality>
        <app-step-eight-personality-social-interaction *ngIf="activeStep$ == 8" class="w-100"></app-step-eight-personality-social-interaction>
        <app-step-nine-life-at-school *ngIf="activeStep$ == 9" class="w-100"></app-step-nine-life-at-school>
        <app-step-ten-general-hisotry *ngIf="activeStep$ == 10" class="w-100"></app-step-ten-general-hisotry>
        <app-step-eleven-family-history *ngIf="activeStep$ == 11" class="w-100"></app-step-eleven-family-history>
        <app-step-twelve-medical-history *ngIf="activeStep$ == 12" class="w-100"></app-step-twelve-medical-history>
        <app-step-infoseek-summary *ngIf="activeStep$ == 13" class="w-100"></app-step-infoseek-summary>
      </ng-container>
    </form>

  </div>
</div>
