import { Component } from '@angular/core';
import { FormService } from '../form/form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  loginSuccess: boolean = false;
  constructor(public FormService: FormService,private routes:Router){
  }
  isLoggedIn(): boolean {
    return this.FormService.isLoggedIn();
  }
  logout() {
    console.log('Logout clicked');
    this.routes.navigate(['/login']);
    this.FormService.setLoggedIn(false);
  }
}
