<div class="row overflow-hidden">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">A.Student Personal Information</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage " *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <!--Student Details-->
       
        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()" class="p-r-20 p-l-20">
            <div id="scrollbar" class="internalform">
              <div class="m-b-32">
                <div class="input-details">
                  <mat-label class="mat-subtitle-2 d-block formlabel marroncolor m-t-40">
                    The following information is provided by<sup class="required-field"></sup>
                  </mat-label>
                </div>
                <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="following_information_providedby" (change)="onRadioChange($event)">
                  <mat-radio-button value="Mother">Mother</mat-radio-button>
                  <mat-radio-button value="Father">Father</mat-radio-button>
                  <mat-radio-button value="Guardian">Guardian</mat-radio-button>
                  <mat-radio-button value="Other">Other</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="nextButtonClicked && stepForm.get('following_information_providedby').hasError('required')" class="error-message">
                  The following information is provided by is required
                </mat-error>
                <mat-error *ngIf="nextButtonClicked && stepForm.get('following_information_providedby').value === 'Other' && !stepForm.get('If_Other').value" class="error-message">
                  The following information is provided by Other is required
                </mat-error>
               
               
              
                <div *ngIf="showTextBox" >
                  <mat-form-field appearance="outline" class="w-100 m-t-20">
                    <input matInput formControlName="If_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                  </mat-form-field>
                </div>

                <div *ngIf="!showTextBox && stepForm.get('If_Other').value">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="If_Other" placeholder="Enter Other Information" />
                  </mat-form-field>
                </div>
              </div>



              <!--Student Details-->
              <div class="m-t-24" >
                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Upload Profile Picture<sup class="required-field"></sup></mat-label>
                <div class="border">
                <button class="uploadbutton" type="button" (click)="uploadButtonClick()">Upload</button>
                <span class="m-l-20"  *ngIf="selectedImageName || studentPhotofilename">{{selectedImageName || studentPhotofilename  }}</span>
                <input type="file" accept="image/" id="digitalsigninput" name="selectedUserProfile" (change)="onProfilePictureSelecte($event)" style="display:none;">
                </div>
                <mat-error *ngIf="nextButtonClicked && !selectedImageName" class="error-message">
                  Please select an image for the profile picture.
                </mat-error>
                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel m-t-20">Student First Name<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput formControlName="Student_FirstName" placeholder="Student First Name" required>
                  
                </mat-form-field>
                 <mat-error *ngIf="nextButtonClicked && stepForm.get('Student_FirstName').hasError('required')" class="error-message">
                  Student First Name is required
                </mat-error>
               
                <div class="row">
                  <div class="col-md-6">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Student Middle Name 1<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Student Middle Name"formControlName="Student_middelname1" >

                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel"> Student Middle Name 2<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Student Middle Name" formControlName="Student_middelname2" >

                    </mat-form-field>
                  </div>
                </div>

                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Student Last Name<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Student Last Name" formControlName="Student_lastname" >
                </mat-form-field>
                <mat-error *ngIf="nextButtonClicked && stepForm.get('Student_lastname').hasError('required') "class="error-message">
                  Student Last Name required
                </mat-error>
                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">DOB <sup class="required-field"></sup> <mat-icon>event</mat-icon></mat-label>
                <mat-form-field appearance="outline" class="w-100" color="primary">
                  <input matInput formControlName="Student_DOB" placeholder="DD/MM/YY" [value]="formattedDOB" readonly>
                  <!-- Add the readonly attribute to prevent editing -->
                </mat-form-field>
                <div class="row">
                  <div class="col-md-12">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Gender<sup class="required-field"></sup></mat-label>
                    <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Gender">
                      <mat-radio-button value="Female">Female</mat-radio-button>
                      <mat-radio-button value="Male">Male</mat-radio-button>

                    </mat-radio-group>
                    <div *ngIf="nextButtonClicked && stepForm.get('Gender').hasError('required')" class="error-message">
                      Gender is required
                    </div>
                  </div>
                </div>
                  <div class="row">
                  <div class="col-md-12">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Blood Group<sup class="required-field"></sup></mat-label>
                    <mat-radio-group aria-label="Select an option" id="nradiobutton" formControlName="BloodGroup" (change)="onRadioChangeblood($event)">
                      <mat-radio-button value="A">A</mat-radio-button>
                      <mat-radio-button value="B">B</mat-radio-button>
                      <mat-radio-button value="AB">AB</mat-radio-button>
                      <mat-radio-button value="O" matTooltip="This is blood group O" matTooltipPosition="above">O</mat-radio-button>
                      <mat-radio-button value="Not Known">Not Known</mat-radio-button>
                      <mat-radio-button value="Other">Other</mat-radio-button>

                    </mat-radio-group>
                    <div *ngIf="nextButtonClicked && stepForm.get('BloodGroup').hasError('required')" class="error-message">
                      Blood Groupis required
                    </div>
                    <div *ngIf="showTextBoxs" >
                      <mat-form-field appearance="outline" class="w-100 m-t-20">
                        <input matInput formControlName="BloodGroup_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                      </mat-form-field>
                    </div>
    
                    <div *ngIf="!showTextBoxs && stepForm.get('BloodGroup_Other').value">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName="BloodGroup_Other" placeholder="Enter Other Information" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                  <div class="row">
                  <div class="col-md-6 m-t-20">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Rh Factor<sup class="required-field"></sup></mat-label>
                    <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Rh_Factor">
                      <mat-radio-button value="+ve">+ve</mat-radio-button>
                      <mat-radio-button value="-ve">-ve</mat-radio-button>
                      <mat-radio-button value="Not Known">Not Known</mat-radio-button>

                    </mat-radio-group>
                    <div *ngIf="nextButtonClicked && stepForm.get('Rh_Factor').hasError('required')" class="error-message">
                      Rh Factor is required
                    </div>
                    
                  </div>
                  </div>
              </div>

              <!--Sibilings Details-->
              <div class="row m-t-32">
                <div class="col-md-6">
                  <div class="input-details">
                    <label for="name" class="formlabel">Number of Sisters</label>
                  </div>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput type="number" placeholder="Number of Sisters"  formControlName="Number_Of_Sisters">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <div class="input-details">
                    <label for="name" class="formlabel">Number of Brothers</label>
                  </div>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Number of Brother" type="number"  formControlName="Number_Of_Brothers">
                  </mat-form-field>
                </div>
              </div>

              <!--Mother Details-->
              <div class="m-t-8  m-b-32">
                <div class="row">
                  <div class="col-md-12">
                    <mat-label class="formlabel">Mother First Name<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100" >
                      <input matInput placeholder="Mother First Name"  formControlName="Mothers_FirstName">
                   
                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Mothers_FirstName').hasError('required')" class="error-message">
                      Mother First Name required
                    </mat-error>
                  </div>
                </div>
                  <div class="row">
                  <div class="col-md-6">
                    <mat-label class="formlabel">Mother Middle Name 1<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Mother Middle Name 1" formControlName="Mothers_MiddleName1">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label class="formlabel">Mother Middle Name 2<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Mother Middle Name 2" formControlName="Mothers_MiddleName2">

                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-label class="formlabel">Mother Last Name<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Mother Last Name" formControlName="Mothers_LastName">
                     
                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Mothers_LastName').hasError('required')" class="error-message">
                      Mother Last Name required
                    </mat-error>
                    <!-- <mat-error *ngIf="nextButtonClicked && stepForm.get('Mothers_FirstName').hasError('required')">
                      Mother Last Name required
                    </mat-error> -->
                  </div>
                </div>
                <div>
                <div class="input-details">
                  <mat-label class="formlabel">Mother Ethnicity<sup class="required-field"></sup></mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="Mothers_Ethnicity" placeholder="Select Mother's Ethnicity" (selectionChange)="MotherEthnicityChange($event)">
                    <mat-option value="Asian/Indian">Asian/Indian</mat-option>
                    <mat-option value="Black African">Black African</mat-option>
                    <mat-option value="Arabs">Arabs</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="nextButtonClicked && ((!stepForm.get('Mothers_Ethnicity').value && !stepForm.get('Mothers_Ethnicity_If_Other').value) || (stepForm.get('Mothers_Ethnicity').value === 'Other' && !stepForm.get('Mothers_Ethnicity_If_Other').value))" class="error-message">
                  Mother Ethnicity is required
                </div>
                <div *ngIf="MotherEthnicity">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName= "Mothers_Ethnicity_If_Other"[maxlength]="maxChars"  placeholder="Enter Other Information" />
                  </mat-form-field>
                </div>

                  <div *ngIf="!MotherEthnicity && stepForm.get('Mothers_Ethnicity_If_Other').value">
                    <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="Mothers_Ethnicity_If_Other"placeholder="Enter Other Information" />
                  </mat-form-field>

                  </div>
                </div>
              </div>

              <!--Father Details-->
              <div class="m-b-32">
                <div class="row">
                  <div class="col-md-12">
                    <mat-label class="formlabel">Father First Name<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Type Here"  formControlName="Fathers_FirstName">
                      
                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Fathers_FirstName').hasError('required')" class="error-message">
                      Father First Name required
                    </mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <mat-label class="formlabel">Father Middle Name 1<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Type Here"  formControlName="Fathers_MiddleName1">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-label class="formlabel">Father Middle Name 2<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Type Here"  formControlName="Fathers_MiddleName2">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <mat-label class="formlabel">Father Last Name<sup class="required-field"></sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Type Here"  formControlName="Fathers_LastName">
                     
                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Fathers_LastName').hasError('required')" class="error-message">
                      Father Last Name required
                    </mat-error>
                  </div>
                </div>
                <div class="input-details">
                  <mat-label class="formlabel">Father Ethnicity<sup class="required-field"></sup></mat-label>
                </div>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="Fathers_Ethnicity" placeholder="Select Mother's Ethnicity" (selectionChange)="FatherChange($event)">
                    <mat-option value="Asian/Indian">Asian/Indian</mat-option>
                    <mat-option value="Black African">Black African</mat-option>
                    <mat-option value="Arabs">Arabs</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="nextButtonClicked && ((!stepForm.get('Fathers_Ethnicity').value && !stepForm.get('Fathers_Ethnicity_If_Other').value) || (stepForm.get('Fathers_Ethnicity').value === 'Other' && !stepForm.get('Fathers_Ethnicity_If_Other').value))" class="error-message">
                  Father Ethnicity is required
                </div>
                <div *ngIf="FatherEthnicity">
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName= "Fathers_Ethnicity_If_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                  </mat-form-field>
                </div>

                  <div *ngIf="!FatherEthnicity && stepForm.get('Fathers_Ethnicity_If_Other').value">
                    <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="Fathers_Ethnicity_If_Other"placeholder="Enter Other Information" />
                  </mat-form-field>


                  </div>

              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-nextbutton (click)="onNextButtonClick()"></app-nextbutton>
              </div>
            </div>
        </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>



