import { Component } from '@angular/core';
import { FormService } from '../../form/form.service';

@Component({
  selector: 'app-nextbutton',
  templateUrl: './nextbutton.component.html',
  styleUrls: ['./nextbutton.component.scss']
})
export class NextbuttonComponent {

  constructor(
    private formservice: FormService ,
  ) {
}

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
}
