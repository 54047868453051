import { Component,Output, EventEmitter,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
// import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {  OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NativeDateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormService } from '../form/form.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
    mobileQuery: MediaQueryList = this.mediaMatcher.matchMedia('(max-width: 600px)');
    showMobileIcon = this.mobileQuery.matches;
    private _mobileQueryListener: () => void;
    displayName='userRegistration';

    otpValue: string[] = ['', '', '', ''];
    invalidDetailsMessage: any;
    successmsg: any;
    version:string='2.0';
    userId: any;
    resendButtonDisabled = false;
    countdownSeconds: number = 0;
    countdownInterval: any;
    otpSentMessage:any;
    otpExpired: boolean = false;
    showResendError: boolean = false;
    invalidDetailsMessages:any;
    mobile: any;


    constructor( private routes:Router ,private formservice:FormService, private breakpointObserver: BreakpointObserver,private fb: FormBuilder,private mediaMatcher: MediaMatcher,private renderer: Renderer2,){
      console.log('Component initialized');
      this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
          console.log('Small screen detected');
        } else {
          console.log('Larger screen detected');
        }
      });
      this._mobileQueryListener = () => (this.showMobileIcon = this.mobileQuery.matches);
      this.mobileQuery.addListener(this._mobileQueryListener);
      breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }




    // terms(){
    //   this.displayName='terms';
    // }
    login(){
      this.routes.navigate(['/otp']);
    }
    termsconditions() {
      try {

        const userId= localStorage.getItem('userId')
        if (!userId) {
          console.error('User ID is missing.');
          return;
        }
        const currentDate = new Date().toISOString().split('T')[0];
        const terms = {
          Terms_and_conditions: true,
          Version: this.version || '', // Ensure default value if this.version is null/undefined
          Date: currentDate,
        };
        // console.log('Updating terms:', terms);
        this.formservice.updateterms(userId,terms).subscribe(
          (data: any) => {
            if (data && data.Status === 200) {
              this.formservice.setLoggedIn(true);

              this.displayName = 'termsupdate';
              this.routes.navigate(['/steps']);
            } else {
              console.error('Failed to update terms. Unexpected response:', data);
            }
          },
          (error: any) => {
            console.error('Error updating terms:', error);
          }
        );
      } catch (error) {
        console.error('Error in termsconditions():', error);
      }
    }






      startCountdown() {
        this.countdownSeconds = 10;
        clearInterval(this.countdownInterval);
        this.countdownInterval = setInterval(() => {
          if (this.countdownSeconds > 0) {
            this.countdownSeconds--;
          } else {
            this.resendButtonDisabled = false;
            clearInterval(this.countdownInterval);
          }
        }, 1000);
      }
      closeErrorMessage() {
        this.showResendError = false;
      }

      destroyed = new Subject<void>();
      currentScreenSize: any;
      studentForm!: FormGroup;
      ngOnInit() {
        this.formservice.setLoggedIn(false);

        if (window.screen.width <= 1200) { // 768px portrait
          this.showMobileIcon = true;
        }
      }
    // constructor(private fb: FormBuilder){
    // }
    displayNameMap = new Map([
      [Breakpoints.XSmall, 'XSmall'],
      [Breakpoints.Small, 'Small'],
      [Breakpoints.Medium, 'Medium'],
      [Breakpoints.Large, 'Large'],
      [Breakpoints.XLarge, 'XLarge'],
    ]);
    ngOnDestroy() {
      this.destroyed.next();
      this.destroyed.complete();
      this.mobileQuery.removeListener(this._mobileQueryListener);
  }
    }
  function userId(userId: any, terms: { Terms_and_conditions: boolean; Version: number; Date: string; }) {
    throw new Error('Function not implemented.');
  }


