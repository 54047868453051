import { Component, Input, inject } from '@angular/core';
import { AbstractControl, ControlContainer, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'form-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent {

  // Deps
  private controlContainer = inject(ControlContainer).control;

  // Input variables
  @Input() name = "";
  @Input() classname = "";
  @Input() inputclass = "";
  @Input() type = "text";
  @Input() placeholder = "";
  @Input() autoComplete = "";
  @Input() isRequired = true;
  @Input() defaultFocus = false;
  @Input() apperance: 'fill' | 'outline';
  //@Input({ required: true }) label = "";

  // Public fields
  public control: AbstractControl | undefined | null = undefined;

  ngAfterViewInit() {
    this.control = this.controlContainer?.get(this.name)
  }

}
