import { Component, OnInit,Input,ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { every } from 'rxjs';
@Component({
  selector: 'app-step-five-confim',
  templateUrl: './step-five-confim.component.html',
  styleUrls: ['./step-five-confim.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepFiveConfimComponent implements OnInit{
  @Input() stepForm!: FormGroup;
  formService: any;
  userId: string;
  formInvalid: boolean = false;
  form: FormGroup;
  PrimaryContact: boolean = false;
  showPetsQuestion: boolean = false;
  showPetInputs: boolean = false;

  nextButtonClicked = false;
  selectedNumbers: any[] = [];
  studentPhotoImageUrl: any;
  studentPhotofilename: any;
  inputs: { sleep: FormControl, input: FormControl }[] = [this.initNapCycleInput()];
  Activites_Other:any
  showOtherInput: boolean=false;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router,
    private cd: ChangeDetectorRef
  ){
    this.form = this.fb.group({
      inputs: this.fb.array(this.inputs.map(input => this.fb.group(input)))
    });
  }
  numberssaved(event: any, value: string) {
    console.log('Checkbox changed:', event, value,this.selectedNumbers);

    if (event.checked) {
      // Add the value to the array if the checkbox is checked
      this.selectedNumbers.push(value);
      
      
    } else {
      // Remove the value from the array if the checkbox is unchecked
      this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
    }

    this.toggleOtherInput();
    console.log('Selected Numbers:', this.selectedNumbers);
    
  }
  toggleOtherInput(){
    console.log("select");
    const activitiesOtherChecked = this.selectedNumbers.includes('Other');
  
    if (activitiesOtherChecked) {
      this.showOtherInput = true; // Show the input field if 'Other' is included in selectedNumbers
    } else {
      this.showOtherInput = false; // Hide the input field if 'Other' is not included in selectedNumbers
      this.stepForm.get('Activites_Other')?.setValue(''); // Clear the input field
    }
  }
  isCheckboxChecked(value: string): boolean {
    const formValue = this.stepForm.get('Which_ofthe_following_activities_doesthestudentenjoy')?.value;
    return formValue ? formValue.includes(value) : false;
  }
  getAnimalPlaceholder(index: number): string {
  return `Animal ${index + 1}`;
}
onNextButtonClick() {
  this.nextButtonClicked = true;

  // this.onNext();
}




markAllFieldsAsTouched(): void {
  const lastInput = this.inputs[this.inputs.length - 1];
  lastInput.sleep.markAsTouched();
  lastInput.input.markAsTouched();
}

PrimaryContactchange(event: any) {
  this.PrimaryContact = event.value === 'Neither';

  // If the input field is not shown, clear the value
  if (!this.PrimaryContact) {
    this.stepForm.get('Activites_Other').setValue('');
  }
}

onPetsRadioChange(event: any) {
  this.showPetInputs = event.value === 'Yes';
  const inputsArray = this.stepForm.get('inputs') as FormArray;

  if (!this.showPetInputs) {
    while (inputsArray.length !== 0) {
      inputsArray.removeAt(0);
    }
  } else {
    if (inputsArray.length === 0) {
      this.addInput();
    }
  }
}




 ngOnInit(): void {

  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");

  // Fetch additional information based on userId
  this.formservice.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];
      this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

      if (this.studentPhotoImageUrl) {
        const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
        this.studentPhotofilename = filename; // Store filename for display
        console.log( this.studentPhotofilename,"image name");
      } else {
        console.warn('studentPhotoImageUrl is null or undefined.');
        this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
      }
      this.stepForm.patchValue({
        What_typeof_recreational_activity_doesthestudentenjoy: InfoseekMasterId['What_typeof_recreational_activity_doesthestudentenjoy'],
        Which_ofthe_following_activities_doesthestudentenjoy: InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'],
        Activites_Other: InfoseekMasterId['Activites_Other'],
        Isthe_student_memberofasports_teamatschool: InfoseekMasterId['Isthe_student_memberofasports_teamatschool'] ||'No',
        Isthe_student_memberofasports_teamatschool_Yes: InfoseekMasterId['Isthe_student_memberofasports_teamatschool_Yes'],
        Do_you_have_pets_at_home: InfoseekMasterId['Do_you_have_pets_at_home'] || 'No',
        Do_you_have_pets_at_home_Yes_Animal: InfoseekMasterId['Do_you_have_pets_at_home_Yes_Animal'],
        Do_you_have_pets_at_home_Yes_Duration: InfoseekMasterId['Do_you_have_pets_at_home_Yes_Duration'],



      });

      const animalNames = InfoseekMasterId['Do_you_have_pets_at_home_Yes_Animal'] ? InfoseekMasterId['Do_you_have_pets_at_home_Yes_Animal'].split(',') : [];
      const durations = InfoseekMasterId['Do_you_have_pets_at_home_Yes_Duration'] ? InfoseekMasterId['Do_you_have_pets_at_home_Yes_Duration'].split(',') : [];
      this.inputs = animalNames.map((animal: string, index: number) => ({
        sleep: new FormControl(animal, Validators.required),
        input: new FormControl(durations[index], Validators.required)
      }));
      
      this.inputs.forEach(input => {
        (this.stepForm.get('inputs') as any).push(this.fb.group(input));
      });
    

      this.showPetInputs = this.stepForm.get('Do_you_have_pets_at_home')?.value === 'Yes';



      const activitiesList = InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy']
      ? InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'].split(',')
      : [];
    
     
      const activitiesOtherChecked = activitiesList;
      this.selectedNumbers = activitiesList;
      this.toggleOtherInput(); // Call the toggleOtherInput function here
      // this.onPetsRadioChange(event)

      console.log(activitiesOtherChecked,"other");
      console.log("Form Values After Patching:", this.stepForm.value);
      this.selectedNumbers = InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy']
      ? InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'].split(',')
      : [];
    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );

  this.stepForm = this.fb.group({
    What_typeof_recreational_activity_doesthestudentenjoy: ['', Validators.required],
    Which_ofthe_following_activities_doesthestudentenjoy: [''],
    Activites_Other: ['', Validators.required],
    Isthe_student_memberofasports_teamatschool: ['', Validators.required],
    Isthe_student_memberofasports_teamatschool_Yes: [''],
    Do_you_have_pets_at_home: ['', Validators.required],
    Do_you_have_pets_at_home_Yes_Animal: [''],
    Do_you_have_pets_at_home_Yes_Duration: [''],
    inputs: this.fb.array([])
  });
}
// toggleOtherInput(event: any) {
//   if (event.checked) {
//     this.stepForm.get('otherActivity')?.enable();
//   } else {
//     this.stepForm.get('otherActivity')?.disable();
//   }
// }
goToPrevious(){
  this.routes.navigate(['/stepfour'])
}

nextButtonClick(): void {
  this.formservice.incrementActiveStep();
}

addInput() {
  this.inputs.push(this.initNapCycleInput());
}

initNapCycleInput(): { sleep: FormControl, input: FormControl } {
  return {
    sleep: new FormControl('', Validators.required),
    input: new FormControl('', Validators.required)
  };
}
removeInput(index: number) {
  this.inputs.splice(index, 1);
}

onSubmit() {

  const sectionD={
    What_typeof_recreational_activity_doesthestudentenjoy:this.stepForm.get('What_typeof_recreational_activity_doesthestudentenjoy')?.value || null,
    Which_ofthe_following_activities_doesthestudentenjoy:this.selectedNumbers.join(',') || null,
    Activites_Other:this.stepForm.get('Activites_Other')?.value || null,
    Isthe_student_memberofasports_teamatschool:this.stepForm.get('Isthe_student_memberofasports_teamatschool')?.value || null,
    Isthe_student_memberofasports_teamatschool_Yes:this.stepForm.get('Isthe_student_memberofasports_teamatschool_Yes')?.value|| null,
    Do_you_have_pets_at_home:this.stepForm.get('Do_you_have_pets_at_home')?.value|| null,
    Do_you_have_pets_at_home_Yes_Animal: this.inputs.map(input => input.sleep.value).join(','),
    Do_you_have_pets_at_home_Yes_Duration: this.inputs.map(input => input.input.value).join(',')

};
console.log(sectionD,"sectionA")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form 0.values to formData

this.formservice.updateUserRegE(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log(data,"sectionf")
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepsix'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);

}

}
