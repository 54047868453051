<div class="step-display steps" id="scrollbar">
  <div *ngFor="let step of stepDetails" class="step">

    <!--Step Icons-->
    <div class="step_number"
     [ngClass]="{
       'selected': activeStep$ === step.step,
       'completed': isStepCompleted(step.step)
     }"
     (click)="setActiveStep(step.step)">
          <img *ngIf="step.step === 1 "[src]="'/assets/images/svgs/stepicons/student-personal-information.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 2" [src]="'/assets/images/svgs/stepicons/health-insurance-information-icon.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 3"  [src]="'/assets/images/svgs/stepicons/address-information.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 4"  [src]="'/assets/images/svgs/stepicons/emergency-contact.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 5"  [src]="'/assets/images/svgs/stepicons/basic-lifestyle.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 6"  [src]="'/assets/images/svgs/stepicons/dietary-habits.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 7"  [src]="'/assets/images/svgs/stepicons/sleep-pattern-quality.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 8"    [src]="'/assets/images/svgs/stepicons/personality-social- interaction.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 9"   [src]="'/assets/images/svgs/stepicons/life-at-school.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 10"  [src]="'/assets/images/svgs/stepicons/general-history.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 11"  [src]="'/assets/images/svgs/stepicons/family-history.svg'" class="w-50" alt="Step {{ step.step }} Image" />
      <img *ngIf="step.step === 12"  [src]="'/assets/images/svgs/stepicons/medical-history.svg'" class="w-50" alt="Step {{ step.step }} Image" />
    </div>

    <!--Step Description-->
    <div class="step_details">
      <div class="step_description m-l-8">
        <a (click)="setActiveStep(step.step)">{{step.description}}</a>
      </div>
    </div>

  </div>
</div>
