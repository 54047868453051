<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container>
      <div class="boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">Consent</p>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform ">
              <div class="row">
                <div class="col-md-12">
                  <div class="col-md-12">You are requested to print the completed form now. This is required to complete the procedure correctly.</div>
                  <div class="m-t-20">In case you cannot immediately take print out of the completed form, Our system will allow you to save an non editable pdf copy. Please take print out of the form at the earliest possible.</div>
                  <div class="m-t-20">Sign and put date in the last section of the page below the consent section in the space provided.</div>
                  <div class="m-t-20">Scan the form and send to infoSeek@nivish.com mentioning UIN # in the subject. you will be notified of the receipt and a second confirmation will be sent via email.</div>
                  <div class="m-t-20">If you are unable to scan and upload the form, send a sealed A4 envelope marked CONFIDENTIAL to Nivish admin unit, c/o lorem ipsum lorem ipsum lorem ipsum. (Retain a copy for your record)</div>
                  <div class="m-t-20">Scans of signed copies are preferable over hard copies by post.</div>
                  <div class="m-t-20 d-flex">
                    <span class="d-flex align-items-center">
                      The last date to receive the signed copy of the form is
                    </span>
                    <span class="d-flex">
                      <mat-form-field appearance="outline" class="w-100 date" style="color: black;">
                        <input matInput placeholder="{{ sevenDaysLaterDate() }}" formControlName="Last_date_signed_copy_of_form" readonly>
                      </mat-form-field>
                    </span>
                  </div>

                  <div class="m-t-20 d-flex">
                    <span class="required-field"></span>
                    <div>
                    I
                    </div>
                    <span class="m-r-8">
                      <mat-form-field appearance="outline" class="w-100">
                        <input type="text" matInput id="name" formControlName="Student_FirstName" placeholder="First Name" />

                      </mat-form-field>
                      <mat-error *ngIf="nextButtonClicked && stepForm.get('Student_FirstName').hasError('required')">
                        Student First Name is required
                      </mat-error>

                    </span>
                    <span class="m-r-8">
                      Parent / Guardian of
                    </span>
                    <span class="m-r-8">
                      <mat-form-field appearance="outline" class="w-100">
                        <input type="text" matInput id="name" formControlName="following_information_providedby"  placeholder="Parent / Guardian of" />
                      </mat-form-field>
                    </span>
                    <span class="m-r-8">
                      of Class
                    </span>
                    <span class="m-r-8">
                      <mat-form-field appearance="outline" class="w-100">
                        <input type="text" matInput id="name" formControlName="class_name" placeholder="Class" />
                      </mat-form-field>
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="m-r-8">
                        <mat-checkbox formControlName="Accepted">
                          <span class="required-field"></span>
                          I have read the policies and I provide my consent to NIVISH.
                        </mat-checkbox>
                      </span>
                      <mat-error *ngIf="nextButtonClicked && stepForm.get('Accepted').hasError('required')">
                        I have read the policies and I provide my consent to NIVISH required
                      </mat-error>
                    </div>


                  </div>
                  <div class="m-t-20">
                    do hereby give my consent to Nivish to :
                    <ol type="1">
                      <li>
                        Create my child's/ ward's electronic health record
                      </li>
                      <li>
                        Update the electronic health record with the information provided in the Infoseek questionnaire and with information gathered from the student/parent/ school/ teacher from time to time.
                      </li>
                      <li>
                        Process and analyze the information/ data gathered and recorded through the school health management program for the benefit of the student / parent , to better understand the health and wellness of the child.
                      </li>
                    </ol>
                  </div>

                  <div>
                    <span class=" required-field"> </span>
                    Signature of
                    <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Signature">
                      <mat-radio-button value="Mother">Mother</mat-radio-button>
                      <mat-radio-button value="Father">Father</mat-radio-button>
                      <mat-radio-button value="Guardian">Guardian</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Signature').hasError('required')">
                      Signature of required
                    </mat-error>

                  </div>
                  <div class="col-sm-8 m-r-32">
                    <input type="file" accept="image/" id="digitalsigninput" name="selectedUserProfile" (change)="onProfilePictureSelecte($event)" style="display:none;">
                    <label for="digitalsigninput" ><img class="uploadedimage" *ngIf="digitalSignImageUrl" [src]="digitalSignImageUrl" >
                        <div *ngIf="!digitalSignImageUrl">
                            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
                        </div>
                    </label>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('selectedUserProfile')?.hasError('required')">
                      {{ errorMessage }}
                    </mat-error>
                </div>
                  <div> <span class=" required-field"></span>

                    Place
                    <mat-form-field appearance="outline" class="w-30">
                      <input type="text" matInput id="name" formControlName="place" placeholder="Place" />
                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('place').hasError('required')">
                      place is required
                    </mat-error>
                  </div>
                  <div> <span class=" required-field"></span>

                    Date
                    <mat-form-field appearance="outline" class="w-30 date" style="color: black;">
                      <input matInput placeholder="{{ getTodayDate() }}" formControlName="Last_date_signed_copy_of_form" readonly>
                    </mat-form-field>
                  
                  </div>
                </div>
              </div>
            </div>

            <div class="row d-flex justify-content-between align-items-center m-t-24">
              <div class="col-md-6">
                <button mat-raised-button class="previewbuttoncolor prenextbutton m-r-24" type="button" (click)="preview()" style="color: rgba(80, 80, 80, 1) !important;">
                  Preview
                </button>
              </div>
              <div class="col-md-6 justify-content-between d-flex">
                <app-previousbutton></app-previousbutton>
                <button mat-raised-button class="nextbutton prenextbutton" (click)="onNextButtonClick()" type="submit">
                  Save & Preview
                </button>
              </div>
            </div>
            
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>



