<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">H. Personality & Social Interaction</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel" >How would you best describe the Social Personality of the Student?</mat-label>
                  <mat-radio-group formControlName="How_would_you_describe_the_social_personality_ofthe_student" (change)="onRadioChange($event)">
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Aggressive">Aggressive</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Attention Seeking">Attention seeking</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Calm">Calm</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Gregarious/Outgoing">Gregarious/ Outgoing</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Happy">Happy</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Nervous">Nervous</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Sad/Morose">Sad/Morose</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Sulky/Cranky">Sulky/Cranky</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Withdrawn">Withdrawn</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Other">Other</mat-radio-button></div>
                    </div>
                  </mat-radio-group>
                 <div *ngIf="showTextBox">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Social_personality_Other"[maxlength]="maxChars" placeholder="Enter Other Information" />
                      <mat-error class="error-message" *ngIf="showTextBox && stepForm.get('Social_personality_Other').invalid && stepForm.get('Social_personality_Other')">Other activity is required.</mat-error>

                    </mat-form-field>
                  </div>

                  <div *ngIf="!showTextBox && stepForm.get('Social_personality_Other').value">
                    <mat-form-field appearance="outline" class="w-100">

                    <input matInput formControlName="Social_personality_Other" placeholder="Enter Other Information" />
                    
                  </mat-form-field>
                  </div>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel">Specify if the Student has any irrational Fears or Phobias</mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Specify here" formControlName="Specify_if_the_student_has_any_irrational_fears_phobias">
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-label class="formlabel">How would you rate the Student’s Self Image/Self Worth?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="How_would_you_rate_the_students_self_image_self_worth">
                    <mat-radio-button value="Excellent">Excellent</mat-radio-button>
                    <mat-radio-button value="Good">Good</mat-radio-button>
                    <mat-radio-button value="Average">Average</mat-radio-button>
                    <mat-radio-button value="Poor">Poor</mat-radio-button>
                  </mat-radio-group>

                </div>

             
                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel" > How would you describe the Student's reaction to Change?</mat-label>
                  <mat-radio-group formControlName="How_would_you_best_describe_the_student_reaction_to_change" (change)="reactionChange($event)">
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Adjusting">Adjusting</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Cheerful">Cheerful</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Fearful">Fearful</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Nervous">Nervous</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Non-Adjusting">Non-adjusting</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Resistant">Resistant</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Obstructive">Obstructive</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Nothing specific">Nothing specific</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Other">Other</mat-radio-button></div>
                    </div>
                  </mat-radio-group>
                  <div *ngIf="reaction">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Student_reaction_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                      <mat-error class="error-message" *ngIf="reaction && stepForm.get('Student_reaction_Other').invalid && stepForm.get('Student_reaction_Other')">Other activity is required.</mat-error>

                    </mat-form-field>
                  </div>

                  <div *ngIf="!reaction && stepForm.get('Student_reaction_Other').value">
                    <mat-form-field appearance="outline" class="w-100">

                    <input matInput formControlName="Student_reaction_Other" placeholder="Enter Other Information" />
                  </mat-form-field>
                  </div>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel" >How would you describe the Student's relationship with other Students?</mat-label>
                  <mat-radio-group formControlName="How_would_you_describe_student_rs_with_other_students"  (change)="relationshipChange($event)">
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Aggressive">Aggressive</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Clingy">Clingy</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Dependent">Dependent</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Dominating">Dominating</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Friendly">Friendly</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Nothing specific">Nothing specific</mat-radio-button></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"><mat-radio-button value="Submissive">Submissive</mat-radio-button></div>
                      <div class="col-md-4"><mat-radio-button value="Other">Other</mat-radio-button></div>
                    </div>

                  </mat-radio-group>
                  <div *ngIf="relationship">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="How_would_you_describe_student_rs_with_other_students_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                      <mat-error class="error-message" *ngIf="relationship && stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').invalid && stepForm.get('How_would_you_describe_student_rs_with_other_students_Other')">Other activity is required.</mat-error>

                    </mat-form-field>
                  </div>

                  <div *ngIf="!relationship && stepForm.get('How_would_you_describe_student_rs_with_other_students_Other').value">
                    <mat-form-field appearance="outline" class="w-100">

                    <input matInput formControlName="How_would_you_describe_student_rs_with_other_students_Other" placeholder="Enter Other Information" />
                  </mat-form-field>
                  </div>
                </div>

                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel"> Does the Student have any significantly close Friends?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="Does_the_student_have_any_significantly_close_friends">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="stepForm.get('Does_the_student_have_any_significantly_close_friends').value === 'Yes'">
                    
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Does_the_student_have_any_significantly_close_friends')" class="error-message">
                      Please provide details about  Student have any significantly close friends?
                    </mat-error>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-label class="formlabel">How many Boys</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="How many boys" formControlName="student_close_friends_yes_how_many_boys">
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-label class="formlabel">How many Girls</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="How many girls" formControlName="student_close_friends_yes_how_many_girls">
                      </mat-form-field>
                    </div>
                  </div>
                  </div>

                </div>
                <div class="col-md-12 m-t-20">
                  <mat-label class="formlabel"> Does the student have any irrational…?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="Does_the_student">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
              </div>
              <div class="col-md-12 m-t-20">
                <mat-label class="formlabel">How would you rate the Student's Cooperation in various household chores.</mat-label>
                <div class="col-md-6 m-t-30 m-l-12">
                  <div class="sliderLabel">
                    <span>{{ sliderLabel }}</span>
                    </div>
             
                  <mat-slider class="mat-slider" [min]="0" [max]="10" discrete="true" (input)="updateSliderValue($event)">
                    <input matSliderThumb formControlName="How_would_you_rate_students_cooperation_in_housedhold_chores">
                  </mat-slider>
                  <div>
                  </div>
              </div>
              <div class="number-container row col-md-12 m-t-10 m-l-12">
                <div *ngFor="let number of sliderScaleValues" class="subh" >{{ number }}</div>
              </div>
              <div class="row d-flex">
                <div class="col-md-6 m-t-20">
                <mat-label class="m-t-64 m-l-40">Very Poor</mat-label>
              </div>
              <div class="col-md-6 m-t-20">
                <mat-label class="m-t-64 m-l-40">Excellent</mat-label>
              </div>
                </div>
              </div>
              </div>
            </div>
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick()"></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>


