import { Injectable } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';






@Injectable({
  providedIn: 'root'
})

export class FormService {

  private activeStepSubject = new BehaviorSubject<number>(1);
  activeStep$ = this.activeStepSubject.asObservable();
  private loggedInKey = 'isLoggedIn';
  public href: string ='';
  baseUrl = window.location.origin;
  url=''
  token=''
  localurl: any;

  incrementActiveStep(): void {
    const nextStep = this.activeStepSubject.value + 1;
    this.setActiveStep(nextStep);
  }
  setLoggedIn(value: boolean) {
    sessionStorage.setItem(this.loggedInKey, value ? 'true' : 'false');
  }
  isLoggedIn(): boolean {
    return sessionStorage.getItem(this.loggedInKey) === 'true';
  }
decrementActiveStep(): void {
  const currentStep = this.activeStepSubject.value;
  const previousStep = currentStep - 1;

  // Highlight both current and previous steps
  this.setActiveStep(currentStep);
  this.setActiveStep(previousStep);
}
  setActiveStep(step: number): void {
    this.activeStepSubject.next(step);
  }

  multiStepForm: FormGroup = this.fb.group({
    personalDetails: this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4)]],
    }),
    planDetails: this.fb.group({
      plan: ['arcade', [Validators.required]],
      duration: ['monthly', [Validators.required]],
      planCost: [9],
      totalCost: [9]
    }),
    addOnDetails: this.fb.group({
      service: [false],
      serviceCost: [0],
      storage: [false],
      storageCost: [0],
      customization: [false],
      customizationCost: [0],
    })
  })

  get stepForm(): FormGroup {
    return this.multiStepForm;
  }

  constructor(private fb: FormBuilder,private http:HttpClient,private routes:Router





    ) {
    this.href = this.routes.url
    //console.log("urlget",this.routes.url)
    console.log("urlgets",this.baseUrl)
  this.localurl === "http://localhost:4205/"


    if ( this.baseUrl === "https://216.48.180.28:4211" || this.baseUrl === "https://nivish-dev-infoseek.sumedhasahni.com" )
    {
      this.url = "https://nivish-dev-api.sumedhasahni.com/";
      this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NDE2MTA4LCJpYXQiOjE3MjE2NDAxMDgsImp0aSI6IjYwZGRmYmZkNWI1YTQwMWE4ZmMxOTA4YTA5NmM2OTYzIiwidXNlcl9pZCI6MTJ9.9rpUHgtrGDPBa-m_lu8Yv_EqhwOR5z_n3dAGJ3-iq9Y";
      console.log(this.baseUrl, "dev url");
      console.log(this.url, "devAPI url");
    }
       if ( this.baseUrl === "https://216.48.180.28:4205" || this.baseUrl === "https://nivish-staging-infoseek.sumedhasahni.com" )
    {
      this.url = "https://nivish-staging-api.sumedhasahni.com/";
      this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NDE2MTA4LCJpYXQiOjE3MjE2NDAxMDgsImp0aSI6IjYwZGRmYmZkNWI1YTQwMWE4ZmMxOTA4YTA5NmM2OTYzIiwidXNlcl9pZCI6MTJ9.9rpUHgtrGDPBa-m_lu8Yv_EqhwOR5z_n3dAGJ3-iq9Y";
      console.log(this.baseUrl, "Staging url");
      console.log(this.url, "Staging url");
    }
    if (this.baseUrl === "http://localhost:4205")
    { this.url = "https://nivish-dev-api.sumedhasahni.com/";
      this.token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcyOTUxMjUwMSwiaWF0IjoxNzIxNzM2NTAxLCJqdGkiOiI1ZTg0NTk0NWZhOWM0MTY0YjQzYWQ5YjNkMjU2ODhhYSIsInVzZXJfaWQiOjF9.jn2lJbd-N9j7Emcgwfh_w8wE1AlLN68uhuDDirqTDeE";
       console.log(this.baseUrl, "local url");
      console.log(this.url, "local url");
     }
  }

   
   

  goToNextStep(number: number) {
    this.activeStepSubject.next(number + 1);
  }

  goBackToPreviousStep(number: number) {
    this.activeStepSubject.next(number - 1);
  }
  submit() {
    //TO-DO => validate form
    this.goToNextStep(4);
    setTimeout(() => {
      this.activeStepSubject.next(1);
    }, 8000);

  }

 
  loginValue= false;
  // token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzIxNjI4Nzc4LCJpYXQiOjE3MTM4NTI3NzgsImp0aSI6ImY1NTEzYjk5YjhlYTQxMDU5YTUwNzI0ZWNjN2Q0NDJhIiwidXNlcl9pZCI6MTJ9.2vcqr7GjKbXIZxUBh77uDiCbI3Qj1fj7RVt0AURsTMo"
  httpHeaders=new HttpHeaders({'Accept': 'application/json,  */*, text/html' ,

  'Authorization': `Bearer ${this.token}`

})

  requestOptions = {headers:this.httpHeaders};
  requestMultiPartOptions = {headers:new HttpHeaders({'Accept': 'multipart/form-data,  */*, text/html' ,

  'Authorization': `Bearer ${this.token}`

})};


  userVerification(userData:any){
    return this.http.post(this.url+"Infoseek/InfoseekOtpGeneration/",userData,this.requestOptions)
  }
  provideremail(userData:any){
    return this.http.post(this.url+"Hcp/ProviderOtpGeneration/",userData,this.requestOptions)
  }

  provideotp(email:any){
    return this.http.post(this.url+"/Hcp/ProviderOtpVerfication/",email,this.requestOptions)
  }
  otpVerfication(email:any){
    return this.http.post(this.url+"Infoseek/InfoseekOtpVerification/",email,this.requestOptions)
  }
  updateUserRegA(id:any,sections:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S2_Verification/"+id,sections,this.requestOptions)
  }
  updateUserRegB(id:any,health:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S3_Verification/"+id,health,this.requestOptions)
  }
  updateUserRegC(id:any,secC:any){
    return this.http.put(this.url+"/Infoseek/Infoseek_S4_Verification/"+id,secC,this.requestOptions)
  }
  updateUserRegD(id:any,secD:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S5_Verification/"+id,secD,this.requestOptions)
  }
  updateUserRegE(id:any,secE:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S6_Verification/"+id,secE,this.requestOptions)
  }
  updateUserRegF(id:any,secF:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S7_Verification/"+id,secF,this.requestOptions)
  }
  updateUserRegG(id:any,secG:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S8_Verification/"+id,secG,this.requestOptions)
  }
  updateUserRegL(id:any,secL:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S13_Verification/"+id,secL,this.requestOptions)
  }
  updateUserRegI(id:any,secI:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S10_Verification/"+id,secI,this.requestOptions)
  }
  updateUserRegJ(id:any,secJ:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S11_Verification/"+id,secJ,this.requestOptions)
  }
  updateUserRegH(id:any,secH:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S9_Verification/"+id,secH,this.requestOptions)
  }
  updateUserRegK(id:any,seck:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S12_Verification/"+id,seck,this.requestOptions)
  }


  updateUserRegM(id:any,secM:any){
    return this.http.put(this.url+"Infoseek/Infoseek_S14_Verification/"+id,secM,this.requestOptions)
  }
  ///////////////////
  providerEducation(educationData:any){
    return this.http.post(this.url+"/Hcp/HcpEducationPost/",educationData, this.requestOptions)
  }
  providerLicense(LicenseData:any){
    return this.http.post(this.url+"Hcp/HcpLicenseDetailsPost/",LicenseData,this.requestOptions)
  }
  getstate(id:any){
    return this.http.get<ApiResponse>(this.url+"Infoseek/StatesGet/"+id,this.requestOptions)
  }
  getallcountry(){
    return this.http.get<ApiResponse>(this.url+"Infoseek/CountryGet/",this.requestOptions)
  }
  getallcitys(id:any){
    return this.http.get<ApisResponse>(this.url+"Infoseek/GetCity/"+id,this.requestOptions)
  }
  hcpVerify(person:any){
    return this.http.post(this.url+"Hcp/HcpOtpGeneration/",person,this.requestOptions)
  }
  hcponeTime(oneCode:any){
    return this.http.post(this.url+"Hcp/HcpOtpVerfication/",oneCode,this.requestOptions)
  }
  licenseUpload(upload:any){
    return this.http.post(this.url+"Hcp/HcpLicenseDetailsPost/",upload,this.requestOptions)
  }
  educationUpload(edu:any){
    return this.http.post(this.url+"Hcp/HcpEducationPost/",edu,this.requestOptions)
  }
  provideallget(filter:any){
    return this.http.get(this.url+"Hcp/HcpMasterGetByType/"+filter+'/',this.requestOptions)
  }
  licensehcpget(){
    return this.http.get(this.url+"Hcp/GetHcpLicenseDetails/",this.requestOptions)
  }
  educationHcpGet(){
    return this.http.get(this.url+"/Hcp/GetHcpEducation/",this.requestOptions)
  }
  personalhcpgetbyid(id:any){
    return this.http.get(this.url+"Hcp/HcpRegistrationGetById/"+id,this.requestOptions)
  }
  personalhcpUpdate(id:any,hcpPerson:any){
    return this.http.put(this.url+"Hcp/HcpRegistrationUpdate/"+id+"/",hcpPerson,this.requestOptions)
  }
  hcpLicenseExpPost(licenseExp:any){
    return this.http.post(this.url+"Hcp/HcpLicenseDetailsExperiencePost/",licenseExp,this.requestOptions)
  }
  hcplicenseget(id:any){
    return this.http.get(this.url+"Hcp/GetHcpLicenseDetails/"+id,this.requestOptions)
  }
  hcpEducationGet(id:any){
    return this.http.get(this.url+"Hcp/GetHcpEducation/"+id,this.requestOptions)
  }
  getbyid(id:any){
    return this.http.get(this.url+"Hcp/HcpMasterGetById/"+id,this.requestOptions)
  }
  personamaster(userspost:any){
    return this.http.post(this.url+"Hcp/HcpRegistrationPost/",userspost, this.requestOptions)
  }
  hcpProviderExpupd(id:any,licenseExp:any){
    return this.http.put(this.url+"/Hcp/HcpExperienceUpdate/"+id+'/',licenseExp,this.requestOptions)
  }
  hcplicenseExpGet(id:any){
    return this.http.get(this.url+"/Hcp/GetHcpExperience/"+id+'/',this.requestOptions)

  }
  InfoseekMasterId(id:any){
    return this.http.get(this.url+"Infoseek/InfoseekVerificationDetails/"+id,this.requestOptions)
  }
  updateterms(id:any,terms:any){
  return this.http.put(this.url+"Infoseek/InfoseekTermsUpdate/"+id,terms,this.requestOptions)
}
hcpterms(id:any,terms:any){
  return this.http.put(this.url+"Hcp/HcpNoteTermsUpdate/"+id+"/",terms,this.requestOptions)
}
providerterms(id:any,terms:any){
  return this.http.put(this.url+"Hcp/ProviderTermsUpdate/"+id,terms,this.requestOptions)
}



//  infosekpost(note:any){
//    return this.http.post(this.url+"/Infoseek/InfoseekNote/",note,this.requestOptions)
//  }
 infosekpost( formData: FormData) {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
  });

  console.log(formData, "hi");

  const url = `${this.url}/Infoseek/InfoseekNote/`;
  return this.http.post(url, formData, { headers });
}
infosekimageuploade( formData: FormData) {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
  });

  console.log(formData, "hi");

  const url = `${this.url}/Infoseek/ImageUploadPost/`;
  return this.http.post(url, formData, { headers });
}
 hcpnote(noteObj:any){
  return this.http.post(this.url+"Hcp/HcpNote/",noteObj,this.requestOptions)
}
gethcplicence(id:any){
return this.http.get(this.url+"Hcp/GetHcpLicenseDetailsId/"+id,this.requestOptions)
}
updatehcplicence(id:any,license:any){
  return this.http.put(this.url+"Hcp/HcpLicenseDetailsUpdate/"+id+"/",license,this.requestOptions)
}

campreview(loginpost:any){
  return this.http.post(this.url+"Hcp/HCPLogin/",loginpost, this.requestOptions)
}
updateNote(id: any, formData: FormData) {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
  });

  console.log(formData, "hi");

  const url = `${this.url}Infoseek/InfoseekPhotoUploadUpdate/${id}`;
  return this.http.put(url, formData, { headers });
}

hcpupdateNote(id: any, formData: FormData) {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
  });

  console.log(formData, "hi");

  const url = `${this.url}Hcp/HcpPhotoUploadUpdate/${id}/`;
  return this.http.put(url, formData, { headers });
}

getidcard(id:any){
  return this.http.get(this.url+"Infoseek/InfoseekIdCardGet/"+id,this.requestOptions)
}
updateProviderNote(id: any, formData: FormData) {
  const headers = new HttpHeaders({
    'Authorization': `Bearer ${this.token}`,
  });

  console.log(formData, "pavan");

  const url = `${this.url}Hcp/HcpNoteUpdate/${id}/`;
  return this.http.put(url, formData, { headers, reportProgress: true, observe: 'events' });
}
providerIdCardGetById(id:any){
  return this.http.get(this.url+"Hcp/HcpRegistrationGetById/"+id,this.requestOptions)
}
eductaiongethcp(id:any){
  return this.http.get(this.url+"Hcp/GetHcpEducationId/"+id,this.requestOptions)
}
educationhcpUpdate(id:any,eduObj:any){
  return this.http.put(this.url+"Hcp/HcpEducationUpdate/"+id+'/',eduObj,this.requestOptions)
}
hcpmasterUpdate(id:any,masObj:any){
  return this.http.put(this.url+"/Hcp/HcpMasterUpdate/"+id+'/',masObj,this.requestOptions)
}
gethcpidcard(id:any){
  return this.http.get(this.url+"Hcp/GetHcpIDCard/"+id,this.requestOptions)
}

}
interface ApiResponse {
  Message: string;
  Result: { id: number; Country: string;CountryID:number ;State: string;}[];
  HasError: boolean;
  Status: number;
}
interface ApisResponse {
  Message: string;
  Result: { id: number; City:string}[];
  HasError: boolean;
  Status: number;
  // hcp ------------------------------------------------------------------------------------------------------------------------------
}


