import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '../../form.service';


@Component({
  selector: 'app-step-infoseek-summary',
  templateUrl: './step-infoseek-summary.component.html',
  styleUrls: ['./step-infoseek-summary.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepInfoseekSummaryComponent implements OnInit {

  profileimage: any;
  selectedUserProfile:any | null = 'null';
  studentPhoto:any | null = 'null';
  digitalSignImageUrl: string | null = null;
  @Input() stepForm!: FormGroup;
  userId: string;
  selectedNumbers: any[] = [];
  

  fileTypeError: boolean = false;
  selectedImage: any;
  infoseekId: string;
  message: string;
  errorMessage: string = 'upload signature  is required';  Upload_Your_Sign: File;
  Last_date_signed_copy_of_form: any;
  gaurdian_of: any;
  parentsname: any;
  typeclass: any;
  signature: any;
  Submitted_date: any;
  place: any;
  Accepted: any;
  of_class: any;
  type_your_name: any;
  formattedDOB: any;
  nextButtonClicked = false;
  UIN: any;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private formService: FormService, // Update this line to inject the service
    private fb: FormBuilder,
    private routes: Router
  ) {}
  onProfilePictureSelecte(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      // Check if the file type is an image
      if (!file.type.startsWith('image/')) {
        this.fileTypeError = true;
        this.errorMessage = 'Invalid file type. Please select an image.';
        return;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {

            // Image size is valid, proceed with the selected image
            this.selectedUserProfile = file;
            this.Upload_Your_Sign = file;
            this.fileTypeError = false;
            this.errorMessage = ''; // Clear any previous error message
            this.selectedImage = e.target.result;
            this.digitalSignImageUrl = e.target.result;

        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.fileTypeError = true;
      this.errorMessage = 'Please select a file.';
      this.selectedImage = '/assets/images/profilepicture.png';
      this.digitalSignImageUrl = '/assets/images/profilepicture.png';
    }
  }

  onNextButtonClick(): void {
    this.nextButtonClicked = true;
    // Handle button click logic here
  }
 ngOnInit(): void {

  this.userId = localStorage.getItem('userId');
  console.log(this.userId, "fghjk");
    
  this.stepForm = this.fb.group({
    Last_date_signed_copy_of_form: ['', Validators.required],
    following_information_providedby: ['', Validators.required],
    Student_FirstName: ['', Validators.required],
    class_name: ['', Validators.required],
    selectedUserProfile: ['', Validators.required],
    Signature: ['', Validators.required],
    Submitted_date: ['', Validators.required],
    place: ['', Validators.required],
    Accepted: [false, Validators.required],
  });
  this.formService.InfoseekMasterId(this.userId).subscribe(
    (data: any) => {
      const InfoseekMasterId = data.Result[0];
      this.stepForm.patchValue({
        Student_FirstName: InfoseekMasterId['Student_FirstName'],
        following_information_providedby: InfoseekMasterId['following_information_providedby'],
        class_name: InfoseekMasterId['class_name'],
        Last_date_signed_copy_of_form: InfoseekMasterId['Last_date_signed_copy_of_form'],
        Submitted_date: InfoseekMasterId['Submitted_date'],
        place: InfoseekMasterId['place'],
        upload_sign: InfoseekMasterId['upload_sign'],
        Accepted: InfoseekMasterId['Accepted'],


       


      });
      console.log(InfoseekMasterId,"InfoseekMasterId")

      console.log("Form Values After Patching:", this.stepForm.value);
     
    },
    (error: any) => {
      console.error('Error fetching InfoseekMasterId:', error);
    }
  );

}
getTodayDate(): string {
  const today = new Date();
  const day = today.getDate().toString().padStart(2, '0');
  
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const month = monthNames[today.getMonth()];

  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
}
sevenDaysLaterDate(): string {
  const today = new Date();
  const sevenDaysLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  const day = sevenDaysLater.getDate().toString().padStart(2, '0');
  
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const month = monthNames[sevenDaysLater.getMonth()];

  const year = sevenDaysLater.getFullYear();

  return `${day}-${month}-${year}`;
}

preview(){
  this.routes.navigate(['/finalpreview'])
}
onSubmit() {
  if (this.stepForm.get('Student_FirstName')?.valid && this.stepForm.get('place')?.valid && this.stepForm.get('Signature')?.valid && this.stepForm.get('Accepted')?.valid)  {

  const dobValue = this.stepForm.get('Last_date_signed_copy_of_form')?.value;
  if (dobValue) {
    const dobDate = new Date(dobValue);
    const dd = String(dobDate.getDate()).padStart(2, '0');
    const mm = String(dobDate.getMonth() + 1).padStart(2, '0');
    const yyyy = dobDate.getFullYear();
    this.formattedDOB = `${yyyy}-${mm}-${dd}`;
  }

  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.log('userId not found in local storage.');
    return;
  }
  this.infoseekId = userId;

  const formData = new FormData();
  formData.append('InfoseekId', this.userId);
  formData.append('Last_date_signed_copy_of_form', this.formattedDOB);
  formData.append('gaurdian_of', this.stepForm.get('following_information_providedby')?.value);
  formData.append('Any_students_family_Other', this.stepForm.get('How_would_you_rate_the_student_overall_physical_health')?.value);
  formData.append('type_your_name', this.stepForm.get('Student_FirstName')?.value);
  formData.append('of_class', this.stepForm.get('class_name')?.value);
  formData.append('Signature', this.stepForm.get('Signature')?.value);
  formData.append('Submitted_date', this.stepForm.get('Submitted_date')?.value);
  formData.append('place', this.stepForm.get('place')?.value);
  formData.append('upload_sign', this.selectedUserProfile);
  formData.append('Accepted', this.stepForm.get('Accepted')?.value);

  console.log(formData);

  this.formService.infosekpost(formData).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.message = 'note';
        this.UIN = data.Result?.UIN;
        const UIN = this.UIN;
        localStorage.setItem('UIN', UIN.toString());


        this.routes.navigate(['/finalpreview']);
      }
      console.log(this.message);
      console.log(data);
    },
    (error: any) => {
      console.error('Error:', error);
      // Handle the error here (e.g., show an error message to the user)
    }
  );
}
}
}




