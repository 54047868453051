import { Component, OnInit,Input,ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-seven-sleep-pattern-quality',
  templateUrl: './step-seven-sleep-pattern-quality.component.html',
  styleUrls: ['./step-seven-sleep-pattern-quality.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepSevenSleepPatternQualityComponent implements OnInit{

  @Input() stepForm!: FormGroup;
  
  formService: any;
  userId: string;
  selectedNumbers: any[] = [];
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  showAdditional = false;
  showOtherInput: boolean=false;
  nextButtonClicked =false
  form: FormGroup;
  inputs: { sleep: FormControl, input: FormControl }[] = [this.initNapCycleInput()];
  // sleep: any;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router,
    private cd: ChangeDetectorRef
    
  ){
    this.form = this.fb.group({
      inputs: this.fb.array(this.inputs.map(input => this.fb.group(input)))
    });
  }
  onNextButtonClick() {
    this.nextButtonClicked = true;
    
  }
  goToPrevious(){
    this.routes.navigate(['/stepsix'])
  }
  addInput() {
    this.inputs.push(this.initNapCycleInput());
    // this.sleep.push(this.initNapCycleInput())
  }
  initNapCycleInput(): { sleep: FormControl, input: FormControl } {
    return {
      sleep: new FormControl('', Validators.required),
      input: new FormControl('', Validators.required)
    };
  }
  removeInput(index: number) {
    this.inputs.splice(index, 1);
  }
  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  
  numberssaved(event: any, value: string) {
    if (event.checked) {
      // Add the value to the array if the checkbox is checked
      this.selectedNumbers = [...this.selectedNumbers, value];
    } else {
      // Remove the value from the array if the checkbox is unchecked
      this.selectedNumbers = this.selectedNumbers.filter((item: string) => item !== value);
    }
    this.toggleOtherInput();

    console.log('Selected Numbers:', this.selectedNumbers);
  }
  toggleOtherInput(){
    console.log("select");
    const activitiesOtherChecked = this.selectedNumbers.includes('Other');
  
    if (activitiesOtherChecked) {
      this.showOtherInput = true; // Show the input field if 'Other' is included in selectedNumbers
    } else {
      this.showOtherInput = false; // Hide the input field if 'Other' is not included in selectedNumbers
      this.stepForm.get('Sleep_related_issues_Other')?.setValue(''); // Clear the input field
    }
  }
  isCheckboxChecked(value: string): boolean {
    const formValue = this.stepForm.get('Does_the_student_have_any_sleep_related_issues_Yes')?.value;
    return formValue ? formValue.includes(value) : false;
  }
  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "fghjk");
  
    // Initialize the form
    this.stepForm = this.fb.group({
      On_an_average_isthe_student_freshandrelaxed_night_sleep: ['', Validators.required],
      Does_the_student_have_any_sleep_related_issues: ['No'],
      Does_the_student_have_any_sleep_related_issues_Yes: [''],
      Sleep_related_issues_Other: ['', Validators.required],
      What_is_the_students_average_length_of_sleep_per_night: ['', Validators.required],
      What_is_the_students_nap_cycle_during_day_Nap: [''],
      What_is_the_students_nap_cycle_during_day_Nap_Duration: [''],
      inputs: this.fb.array([]) // Initialize FormArray
    });
  
    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];
  
        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log(this.studentPhotofilename, "image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
  
        const napCycleString = InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap'] || '';
        const napCycleValues = napCycleString.split(',').map(value => value.trim());
  
        const durationsString = InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap_Duration'] || '';
        const durations = durationsString.split(',').map(value => value.trim());
  
        this.inputs = napCycleValues.map((animal: string, index: number) => ({
          sleep: new FormControl(animal, Validators.required),
          input: new FormControl(durations[index] || '', Validators.required)
        }));
  
        const inputsArray = this.stepForm.get('inputs') as FormArray;
  
        this.inputs.forEach(input => {
          inputsArray.push(this.fb.group(input));
        });
  
        this.stepForm.patchValue({
          On_an_average_isthe_student_freshandrelaxed_night_sleep: InfoseekMasterId['On_an_average_isthe_student_freshandrelaxed_night_sleep'] || '',
          Does_the_student_have_any_sleep_related_issues: InfoseekMasterId['Does_the_student_have_any_sleep_related_issues'] || 'No',
          Does_the_student_have_any_sleep_related_issues_Yes: InfoseekMasterId['Does_the_student_have_any_sleep_related_issues_Yes'] || '',
          Sleep_related_issues_Other: InfoseekMasterId['Sleep_related_issues_Other'] || '',
          What_is_the_students_average_length_of_sleep_per_night: InfoseekMasterId['What_is_the_students_average_length_of_sleep_per_night'] || '',
          What_is_the_students_nap_cycle_during_day_Nap: napCycleString,
          What_is_the_students_nap_cycle_during_day_Nap_Duration: durationsString,
        });
  
        console.log("Form Values After Patching:", this.stepForm.value);
        console.log("InfoseekMasterId", InfoseekMasterId);
  
        this.selectedNumbers = InfoseekMasterId['Does_the_student_have_any_sleep_related_issues_Yes']
          ? InfoseekMasterId['Does_the_student_have_any_sleep_related_issues_Yes'].split(',')
          : [];
        this.toggleOtherInput();
      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );
    }
onSubmit() {

  const sectionD={
    On_an_average_isthe_student_freshandrelaxed_night_sleep:this.stepForm.get('On_an_average_isthe_student_freshandrelaxed_night_sleep')?.value || null,
    Does_the_student_have_any_sleep_related_issues:this.stepForm.get('Does_the_student_have_any_sleep_related_issues')?.value|| null,
    Does_the_student_have_any_sleep_related_issues_Yes:this.selectedNumbers.join(',')|| null,
    Sleep_related_issues_Other:this.stepForm.get('Sleep_related_issues_Other')?.value|| null,
    What_is_the_students_average_length_of_sleep_per_night:this.stepForm.get('What_is_the_students_average_length_of_sleep_per_night')?.value|| null,
    // What_is_the_students_nap_cycle_during_day_Nap: this.inputs.map(input => `${input.sleep.value}, ${input.input.value}`).join(','), 
    What_is_the_students_nap_cycle_during_day_Nap: this.inputs.map(input => input.sleep.value).join(','),
    What_is_the_students_nap_cycle_during_day_Nap_Duration : this.inputs.map(input => input.input.value).join(',') }
    console.log(sectionD,"sectionD")

const userId = localStorage.getItem('userId');
const formData = new FormData();

// Append form values to formData

this.formservice.updateUserRegG(userId, sectionD).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      console.log('User data updated successfully');
      //this.routes.navigate(['/stepeight'])
      this.nextButtonClick();
    }
  },
  (error) => {
    console.error('Error updating user data:', error);
  }
);
}

}



