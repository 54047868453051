import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StepEightPersonalitySocialInteractionComponent } from './components/form/form-steps/step-eight-personality-social-interaction/step-eight-personality-social-interaction.component';
import { StepElevenFamilyHistoryComponent } from './components/form/form-steps/step-eleven-family-history/step-eleven-family-history.component';
import { StepFiveConfimComponent } from './components/form/form-steps/step-five-confim/step-five-confim.component';
import { StepFourComponent } from './components/form/form-steps/step-four/step-four.component';
import { StepInfoseekSummaryComponent } from './components/form/form-steps/step-infoseek-summary/step-infoseek-summary.component';
import { StepNineLifeAtSchoolComponent } from './components/form/form-steps/step-nine-life-at-school/step-nine-life-at-school.component';
import { StepOneStudentPersonalInfoComponent } from './components/form/form-steps/step-one-student-personal-info/step-one-personal-details.component';
import { StepSevenSleepPatternQualityComponent } from './components/form/form-steps/step-seven-sleep-pattern-quality/step-seven-sleep-pattern-quality.component';
import { StepSixComponent } from './components/form/form-steps/step-six/step-six.component';
import { StepTenGeneralHisotryComponent } from './components/form/form-steps/step-ten-general-hisotry/step-ten-general-hisotry.component';
import { StepThreeAddOnsComponent } from './components/form/form-steps/step-three-add-ons/step-three-add-ons.component';
import { StepTwelveMedicalHistoryComponent } from './components/form/form-steps/step-twelve-medical-history/step-twelve-medical-history.component';
import { StepTwoHealthInsuranceInformationComponent } from './components/form/form-steps/step-two-health-insurance-information/step-two-health-insurance-information';
import { FormComponent } from './components/form/form.component';
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component';
import { TermsComponent } from './components/terms/terms.component';
import { FinalPreviewComponent } from './components/form/form-steps/final-preview/final-preview.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';


const routes: Routes = [
  {path:'',pathMatch:'full',redirectTo:'login'},
  { path:'login', component:LoginComponent},
  { path:'otp', component:OtpComponent},
  { path:'terms', component:TermsComponent},
  { path:'steps', component:FormComponent},
  { path:'stepone', component:StepOneStudentPersonalInfoComponent},
  { path:'steptwo', component:StepTwoHealthInsuranceInformationComponent},
  { path:'stepthree', component:StepThreeAddOnsComponent},
  { path:'stepfour', component:StepFourComponent},
  { path:'stepfive', component:StepFiveConfimComponent},
  { path:'stepsix', component:StepSixComponent},
  { path:'stepseven', component:StepSevenSleepPatternQualityComponent},
  { path:'stepeight', component:StepEightPersonalitySocialInteractionComponent},
  { path:'stepnine', component:StepNineLifeAtSchoolComponent},
  { path:'stepten', component:StepTenGeneralHisotryComponent},
  { path:'stepeleven', component:StepElevenFamilyHistoryComponent},
  { path:'stepetwelve', component:StepTwelveMedicalHistoryComponent},
  { path:'summary', component:StepInfoseekSummaryComponent},
  { path:'finalpreview', component:FinalPreviewComponent},

  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
