<div class="row">
  <div class="col-lg-12">
    <mat-toolbar class="header header-height-width ">
      <img class="m-l-40" src="/assets/images/svgs/nivish-logo.svg">
      <div *ngIf="isLoggedIn()">
        <button mat-icon-button class="rounded-button" (click)="logout()">
  
          <mat-icon>power_settings_new</mat-icon>
        </button>
  
      </div> 
    </mat-toolbar>
     </div>
</div>
