import { Component, OnInit } from '@angular/core';
import { FormService } from '../form/form.service';

@Component({
  selector: 'app-step-tracker-icons',
  templateUrl: './step-tracker-icons.component.html',
  styleUrls: ['./step-tracker-icons.component.scss']
})
export class StepTrackerIconsComponent implements OnInit {
  stepDetails: { step: number; description: string; completed?: boolean ;url?: string;filled?: boolean;dataFilled?: boolean}[] = [
    { step: 1, description: 'A. Student Personal Information', url: '/steps', dataFilled: false },
    { step: 2, description: 'B. Health Insurance Information', url: '/steptwo' ,dataFilled: false},
    { step: 3, description: 'C. Address Information', url: '/stepthree', dataFilled: false },
    { step: 4, description: 'D. Emergency Contact', url: '/stepfour',dataFilled: false },
    { step: 5, description: 'E. Basic Lifestyle', url: '/stepfive',dataFilled: false , },
    { step: 6, description: 'F. Dietary Habits', url: '/stepsix',dataFilled: false  },
    { step: 7, description: 'G. Sleep Pattern & Quality', url: '/stepseven',dataFilled: false  },
    { step: 8, description: 'H. Personality & Social Interaction', url: '/stepeight',dataFilled: false  },
    { step: 9, description: 'I. Life At School', url: '/stepnine' ,dataFilled: false },
    { step: 10, description: 'J. General History', url: '/stepten',dataFilled: false  },
    { step: 11, description: 'K. Family History', url: '/stepeleven',dataFilled: false  },
    { step: 12, description: 'L. Medical History', url: '/stepetwelve',dataFilled: false  },
  ]
  activeStep$: number;

  constructor(private formService: FormService) { }

  ngOnInit(): void {
    this.formService.activeStep$.subscribe(
      activeStep => this.activeStep$ = activeStep);
  }
 
  isStepCompleted(step: number): boolean {
    const currentStep = this.stepDetails.find(s => s.step === step);
    return currentStep && (step <= this.activeStep$ || currentStep.completed || currentStep.dataFilled);
  }
  setActiveStep(step: number): void {
    if (step > this.activeStep$ + 1) {
      return;
    }

    // Set dataFilled to true for all remaining steps when moving to the next step
    const currentStep = this.stepDetails.find(s => s.step === step);
    if (currentStep) {
      currentStep.dataFilled = true;
    }

    this.formService.setActiveStep(step);
  }

  isStepFilled(step: number): boolean {
    const currentStep = this.stepDetails.find(s => s.step === step);
    return currentStep && (currentStep.completed || currentStep.dataFilled);
  }
}