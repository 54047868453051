<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">E. Basic Life Style</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform m-t-40">
              <!--Primary Contact Details-->
              <div class="row m-t-40">
                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">What kind of Recreational Activity does the Student enjoy the most?<sup class="required-field"></sup></mat-label>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" class="m-b-32"  formControlName="What_typeof_recreational_activity_doesthestudentenjoy" (change)="PrimaryContactchange($event)">
                    <mat-radio-button value="Outdoor">Outdoor</mat-radio-button>
                    <mat-radio-button value="Indoor">Indoor</mat-radio-button>
                    <mat-radio-button value="Both">Both</mat-radio-button>
                    <mat-radio-button value="Neither">Neither</mat-radio-button>
                  </mat-radio-group>

                </div>

                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Which of the following Activities does the Student participate in?<sup class="required-field"></sup></mat-label>
                  <section>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Athletics')" (change)="numberssaved($event, 'Athletics')">Athletics</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Cycling')" (change)="numberssaved($event, 'Cycling')">Cycling</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Gym')" (change)="numberssaved($event, 'Gym')">Gym</mat-checkbox></div>
                    </div>
                    <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Music')" (change)="numberssaved($event, 'Music')">Music</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Online social networking')" (change)="numberssaved($event, 'Online social networking')">Online Social Networking</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Painting/Sketching')" (change)="numberssaved($event, 'Painting/Sketching')">Painting/Sketching</mat-checkbox></div>
                  </div>
                  <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Playing computer games')" (change)="numberssaved($event, 'Playing computer games')">Playing Computer Games</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Reading books')" (change)="numberssaved($event, 'Reading books')">Reading Books</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Sports')" (change)="numberssaved($event, 'Sports')">Sports</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Swimming')" (change)="numberssaved($event, 'Swimming')">Swimming</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Watching TV')" (change)="numberssaved($event, 'Watching TV')">Watching TV</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Yoga/Pilates')" (change)="numberssaved($event, 'Yoga/Pilates')">Yoga/Pilates</mat-checkbox></div>
                    </div>
                      <div class="row">
                        <div class="col-md-4">
                          <mat-checkbox [checked]="isCheckboxChecked('Other')" (change)="numberssaved($event, 'Other')">Other</mat-checkbox>
                      </div>
                    </div>
                    <div class="row" *ngIf="showOtherInput">
                      <div class="col-md-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <input matInput placeholder="If others, type here"  formControlName="Activites_Other">
                          <mat-error class="error-message"    *ngIf="showOtherInput && stepForm.get('Activites_Other').invalid && stepForm.get('Activites_Other')">Other activity is required.</mat-error>

                        </mat-form-field>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Is the Student a member of any Sports team at School?<sup class="required-field"></sup></mat-label>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Isthe_student_memberofasports_teamatschool" class="m-b-32">
                      <mat-radio-button value="Yes">Yes</mat-radio-button>
                      <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="stepForm.get('Isthe_student_memberofasports_teamatschool').value === 'Yes'">
                      <mat-form-field appearance="outline" class="w-100">
                          <input matInput placeholder="If yes, which one ?" formControlName="Isthe_student_memberofasports_teamatschool_Yes">
                      </mat-form-field>
                      <mat-error *ngIf="nextButtonClicked && stepForm.get('Isthe_student_memberofasports_teamatschool_Yes').hasError('required')">
                          Input is required if the student is a member of a sports team.
                      </mat-error>
                
                  </div>
              </div>
              <div class="col-md-12">
                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">
                  Do you have Pets at home?<sup class="required-field"></sup>
                </mat-label>
                <!-- <mat-radio-group
                  id="nradiobutton"
                  aria-label="Select an option"
                  formControlName="Do_you_have_pets_at_home"
                  class="m-b-32"
                >
                  <mat-radio-button value="Yes">Yes</mat-radio-button>
                  <mat-radio-button value="No">No</mat-radio-button>
                </mat-radio-group> -->
                <mat-radio-group
                id="nradiobutton"
                aria-label="Select an option"
                formControlName="Do_you_have_pets_at_home"
                class="m-b-32"
                (change)="onPetsRadioChange($event)"
              >
                <mat-radio-button value="Yes">Yes</mat-radio-button>
                <mat-radio-button value="No">No</mat-radio-button>
              </mat-radio-group>
              
              <div *ngIf="showPetInputs">
                <div class="row" *ngFor="let input of inputs; let i = index">
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="Pet Animal" [formControl]="input.sleep">
                      <mat-error *ngIf="input.sleep.invalid && input.sleep.touched">nap is required.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="(Duration in months)" [formControl]="input.input">
                      <mat-error *ngIf="input.input.invalid && input.input.touched">Duration is required.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 m-t-9">
                    <button type="button" class="nextbutton" mat-raised-button (click)="addInput()">+</button>
                    <button mat-raised-button class="nextbutton m-l-20" (click)="removeInput(i)" *ngIf="inputs.length > 1">-</button>
                  </div>
                </div>
              </div>
              </div>
              
              </div>
            </div>

            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton></app-nextbutton>
              </div>
            </div>
          </form>
          <!--Seconday Contact Details-->

          <!--Doctor Details-->
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
