import { Component, OnInit,Input } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../../form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-three-add-ons',
  templateUrl: './step-three-add-ons.component.html',
  styleUrls: ['./step-three-add-ons.component.scss'],
  providers: [FormGroupDirective]  // Add this line

})
export class StepThreeAddOnsComponent implements OnInit {
  stepForm: FormGroup;
  timeFrame: string | any = 'monthly' || 'yearly';
  // previousCost = this.rootFormGroup.form.controls['planDetails'];
  userId: string;
  @Input() formGroupName!: string;
  studentPhotoImageUrl: any;
  studentPhotofilename: string;
  CountryList: any[] = [];
  statesList:any[]=[];
  form: any;
  nextButtonClicked = false


  constructor(
    private rootFormGroup: FormGroupDirective,
    private formservice: FormService ,
    private fb: FormBuilder,
    private routes:Router
  ) { }


  ngOnInit(): void {
    // this.timeFrame = this.previousCost.value.duration;
    // this.updateAddOns();
    this.fetchCountry()

    this.userId = localStorage.getItem('userId');
    console.log(this.userId, "userId");

    // Fetch additional information based on userId
    this.formservice.InfoseekMasterId(this.userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
        this.statesList = [InfoseekMasterId['State']];
        this.stepForm.patchValue({
          Building_Name: InfoseekMasterId['Building_Name'],
          Apartment_Villa_No: InfoseekMasterId['Apartment_Villa_No'],
          Street_No: InfoseekMasterId['Street_No'],
          Area: InfoseekMasterId['Area'],
          City: InfoseekMasterId['City'],
          Country: InfoseekMasterId['Country'],
          State: InfoseekMasterId['State'],
          Zip_Code: InfoseekMasterId['Zip_Code'],
          Emergency_MobileNumber_Registered_with_school: InfoseekMasterId['Emergency_MobileNumber_Registered_with_school'],
          Alternate_MobileNumber: InfoseekMasterId['Alternate_MobileNumber'],
          Belongs_To: InfoseekMasterId['Belongs_To'],    
          Email_Registered_With_School: InfoseekMasterId['Email_Registered_With_School'],


        });
        console.log('InfoseekMasterId:', InfoseekMasterId);

        console.log("Form Values After Patching:", this.stepForm.value);

      },
      (error: any) => {
        console.error('Error fetching InfoseekMasterId:', error);
      }
    );

    this.stepForm = this.fb.group({
      Building_Name: ['', Validators.required],
      Apartment_Villa_No: ['', Validators.required],
      Street_No: ['', Validators.required],
      Area: ['', Validators.required],
      City: ['', Validators.required],
      Country: ['', Validators.required],
      State: ['', Validators.required],
      Zip_Code: ['', Validators.required],
      Emergency_MobileNumber_Registered_with_school: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      Alternate_MobileNumber: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      Belongs_To: ['', Validators.required],
      Email_Registered_With_School: ['', Validators.required],

    });
  }
  
  onNextButtonClick() {
    this.nextButtonClicked = true;
  }
  goToPrevious(){
    this.routes.navigate(['/steptwo'])
  }

  nextButtonClick(): void {
    this.formservice.incrementActiveStep();
  }
  onCountrySelected() {
    const selectedCountry = this.stepForm.get('Country')?.value;
    
    if (selectedCountry) {
      this.getStatesForCountry(selectedCountry);
      // console.log(this.getStatesForCountry,"getStatesForCountry")
    }
  }
  onstateSelected() {
    const selectedCountry = this.stepForm.get('State')?.value;
    
    if (selectedCountry) {
      this.getStatesForCountry(selectedCountry);
    }
  }
 
  getStatesForCountry(country: any) {
    this.formservice.getstate(country).subscribe(
      (response: any) => {
        if (response && response.Result && response.Result.length > 0) {
          this.statesList = response.Result;
          console.log(this.statesList, 'statesList');
        } else {
          console.warn('No states found for', country);
          // Display a message to the user indicating no states were found
        }
      },
      (error: any) => {
        console.error('Error fetching states:', error);
        // Handle HTTP error (e.g., display error message to the user)
      }
    );
  }
  
  fetchCountry() {
    this.formservice.getallcountry().subscribe(response => {
      if (response && response.Result) {
        // Assuming `Country` property in each object contains the country name
        this.CountryList = response.Result;
        // console.log(this.CountryList, "countrylist");
      }
    });
  }
  onSubmit() {
    if (
      this.stepForm.get('Building_Name')?.valid &&
      this.stepForm.get('Apartment_Villa_No')?.valid &&
      this.stepForm.get('Street_No')?.valid &&
      this.stepForm.get('Area')?.valid &&
      this.stepForm.get('City')?.valid &&
      this.stepForm.get('Country')?.valid &&
      this.stepForm.get('State')?.valid &&
      this.stepForm.get('Zip_Code')?.valid &&
      this.stepForm.get('Email_Registered_With_School')?.valid &&
      this.stepForm.get('Emergency_MobileNumber_Registered_with_school')?.valid &&

      (!this.stepForm.get('Alternate_MobileNumber')?.value || this.stepForm.get('Belongs_To')?.valid) // Ensure Belongs_To is valid only if Alternate_MobileNumber is provided
       )   {
      const sectionC = {
        Building_Name: this.stepForm.get('Building_Name')?.value || null,
        Apartment_Villa_No: this.stepForm.get('Apartment_Villa_No')?.value || null,
        Street_No: this.stepForm.get('Street_No')?.value || null,
        Area: this.stepForm.get('Area')?.value || null,
        City: this.stepForm.get('City')?.value || null,
        Country: this.stepForm.get('Country')?.value || null,
        State: this.stepForm.get('State')?.value || null,
        Zip_Code: this.stepForm.get('Zip_Code')?.value || null,
        Emergency_MobileNumber_Registered_with_school: this.stepForm.get('Emergency_MobileNumber_Registered_with_school')?.value || null,
        Alternate_MobileNumber: this.stepForm.get('Alternate_MobileNumber')?.value || null,
        Belongs_To: this.stepForm.get('Belongs_To')?.value,
        Email_Registered_With_School: this.stepForm.get('Email_Registered_With_School')?.value || null,
      };
  
      console.log('Submitting sectionC:', sectionC);
  
      const userId = localStorage.getItem('userId');
  
      this.formservice.updateUserRegC(userId, sectionC).subscribe(
        (data: any) => {
          console.log('Update response:', data);
          if (data && data['Status'] === 200) {
            console.log('User data updated successfully');
            // this.routes.navigate(['/stepfour'])
            this.nextButtonClick();
          } else {
            console.error('Update failed:', data);
          }
        },
        (error) => {
          console.error('Error updating user data:', error);
        }
      );
    } else {
      console.log('Form validation failed.');
    }
  }}