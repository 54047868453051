import { Component,Output, EventEmitter,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
// import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {  OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { NativeDateAdapter } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormService } from '../form/form.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  mobileQuery: MediaQueryList = this.mediaMatcher.matchMedia('(max-width: 600px)');
  showMobileIcon = this.mobileQuery.matches;
  private _mobileQueryListener: () => void;
  displayName='userRegistration';
  formData:any= {studentFirstName:'',
  studentDOB:'',
  mothersFirstName:'',
  emailId:'',}
  verificationCode='';
  invalidDetailsMessage: any;
  successmsg: any;
  result: any;
  studentPersonalInformation:any;
  studentMiddleName1:any;
  studentMiddleName2:any;
  studentLastName:any;
  dateofBirth: string = '';
  age:number | null = null;
  noofSisters:any;
  noOfBrothers:any;
  sleeppernight:any;
  healthInsuranceInformation: any = {};
  addressInformation: any = {};
  guardianInformation: any = {};
  // displayName='sectionA';
  healthInsurance:any;
  // studentFirstName:any;
  showResendError: boolean = false;
  invalidDetailsMessages:any;
  mobile: any;
  router: any;
  formatDob: string;
  today = new Date();
  formattedDate: string | null = null; 

  constructor( private routes:Router ,private formservice:FormService, private breakpointObserver: BreakpointObserver,private fb: FormBuilder,private mediaMatcher: MediaMatcher,private renderer: Renderer2,){
    console.log('Component initialized');
    this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
        console.log('Small screen detected');
      } else {
        console.log('Larger screen detected');
      }
    });
    this._mobileQueryListener = () => (this.showMobileIcon = this.mobileQuery.matches);
    this.mobileQuery.addListener(this._mobileQueryListener);
    breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
    .pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
        }
      }
    });
}

formatDate(date: Date | null): string | null {
  if (date) {
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = date.toLocaleDateString('en-US', { month: 'long' });
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  } else {
    return null;
  }
}

  onSubmit() {
    const dobValue = this.studentForm.get('dateOfBirth')?.value;
    if (dobValue) {
      const dobDate = new Date(dobValue);
      const dd = String(dobDate.getDate()).padStart(2, '0');
      const mm = String(dobDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = dobDate.getFullYear();
      const formattedDOB = `${yyyy}-${mm}-${dd}`;

    const details = {
      Student_FirstName: this.studentForm.get('firstName')?.value,
      Student_DOB: formattedDOB,
      Mothers_FullName: this.studentForm.get('motherFirstName')?.value,
      Email: this.studentForm.get('Email')?.value,
    };
    console.log(details,'login');
    localStorage.setItem('details', JSON.stringify(details));
    this.formservice.userVerification(details).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'Verification successful';
          this.invalidDetailsMessage = '';
          this.routes.navigate(['/otp']);
          this.displayName = 'otp';
        } else if (data['Status'] === 400) {
          this.successmsg = '';
          this.invalidDetailsMessage = data['Message'];
        } else {
          this.successmsg = '';
          this.invalidDetailsMessage = 'An error occurred';
        }
        console.log(data, 'kiran')
      },
      (error) => {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Verification failed. Please check your credentials and try again';
      }
    );
    }
  }
  terms(){
    this.displayName='terms';
  }
  otp(){
    this.displayName='otp';
  }
  // terms(){
  //   this.displayName='terms';
  // }
  user(){
    this.displayName='userRegistration';
  }







    closeErrorMessage() {
      this.showResendError = false;
    }
    formatCountdownTime(seconds: number): string {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
      return `${formattedMinutes}:${formattedSeconds}`;
    }
    destroyed = new Subject<void>();
    currentScreenSize: any;
    studentForm!: FormGroup;
    ngOnInit() {
      this.studentForm = this.fb.group({
        firstName: ['', [Validators.required]],
        dateOfBirth: ['', [Validators.required]],
        motherFirstName: ['', [Validators.required]],
        Email: ['', [Validators.required]],
      });
      if (window.screen.width <= 1200) { // 768px portrait
        this.showMobileIcon = true;
      }
    }
  // constructor(private fb: FormBuilder){
  // }
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.mobileQuery.removeListener(this._mobileQueryListener);
}
  }
function userId(userId: any, terms: { Terms_and_conditions: boolean; Version: number; Date: string; }) {
  throw new Error('Function not implemented.');
}









