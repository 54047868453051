<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container>
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">L. Medical History</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <mat-label class="formlabel" >Did the Student have any significant medical Issue in the Past? (Tick all that are applicable)</mat-label>
                  <mat-radio-group id="nradiobutton" (change)="toggleCheckboxes($event)" formControlName="Does_the_student_have_any_medicalissue_In_The_Past">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                  <section *ngIf="showCheckboxes">  
                      <div class="row">
                        <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Acne vulgaris')" (change)="onAcneVulgarisCheckboxChange($event)">Acne vulgaris</mat-checkbox></div>
                        <div class="row" *ngIf="showRelationshipCheckboxes">
                          <div class="col-md-12 m-l-20">
                          <mat-label class="formlabel">Relation</mat-label>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Mother</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Father</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Grandmother</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Grandfather</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Brother</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Sister</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Uncle</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Aunt</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Cousin Brother</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Cousin Sister</mat-checkbox>
                          </div>
                          <div class="col-md-4">
                            <mat-checkbox>Other</mat-checkbox>
                          </div>
                          <div class="col-md-6">
                            <mat-label class="formlabel m-l-20">Age</mat-label>
                            <mat-form-field appearance="outline" class="w-100 m-t-18">
                              <input matInput  placeholder="Enter Age" />
                            </mat-form-field>
                          </div>
                        </div>
                      
                        
                        <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Anemia')" (change)="numberssaved($event, 'Anemia')">Anemia</mat-checkbox></div>
                        <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Appendicitis')" (change)="numberssaved($event, 'Appendicitis')">Appendicitis</mat-checkbox></div>
                      </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Asthma')" (change)="numberssaved($event, 'Asthma')">Asthma</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Attention/Learning disorders')" (change)="numberssaved($event, 'Attention/Learning disorders')">Attention/Learning disorders</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Autism')" (change)="numberssaved($event, 'Autism')">Autism</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Bleeding problems')" (change)="numberssaved($event, 'Bleeding problems')">Bleeding problems</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Cancer')" (change)="numberssaved($event, 'Cancer')">Cancer</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Cerebral palasy')" (change)="numberssaved($event, 'Cerebral palasy')">Cerebral Palsy</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Chicken pox')" (change)="numberssaved($event, 'Chicken pox')">Chicken pox</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Covid')" (change)="numberssaved($event, 'Covid')">Covid-19</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Cystic fibrosis')" (change)="numberssaved($event, 'Cystic fibrosis')">Cystic fibrosis</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Dental problems')" (change)="numberssaved($event, 'Dental problems')">Dental problems</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Ear infections')" (change)="numberssaved($event, 'Ear infections')">Ear infections</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Epilepsy/Seizure/Convulsions')" (change)="numberssaved($event, 'Epilepsy/Seizure/Convulsions')">Epilepsy/Seizure/Convulsions</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('G6PD deficiency')" (change)="numberssaved($event, 'G6PD deficiency')">G6PD deficiency</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('German measles')" (change)="numberssaved($event, 'German measles')">German measles</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Heart problems')" (change)="numberssaved($event, 'Heart problems')">Heart problems</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hearing problems')" (change)="numberssaved($event, 'Hearing problems')">Hearing problems</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Hernia')" (change)="numberssaved($event, 'Hernia')">Hernia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('High blood pressure')" (change)="numberssaved($event, 'High blood pressure')">High blood pressure</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('HIV infection')" (change)="numberssaved($event, 'HIV infection')">HIV infection</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Juvenile diabetes')" (change)="numberssaved($event, 'Juvenile diabetes')">Juvenile diabetes</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Kidney problems')" (change)="numberssaved($event, 'Kidney problems')">Kidney problems</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Learning disabilities')" (change)="numberssaved($event, 'Learning disabilities')">Learning disabilities</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Measles')" (change)="numberssaved($event, 'Measles')">Measles</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Meningitis')" (change)="numberssaved($event, 'Meningitis')">Meningitis</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Mental illness')" (change)="numberssaved($event, 'Mental illness')">Mental illness</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Mumps')" (change)="numberssaved($event, 'Mumps')">Mumps</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Mumps')" (change)="numberssaved($event, 'Menstrual Disorder')">Menstrual Disorder</mat-checkbox></div>
                      </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Mumps')" (change)="numberssaved($event, 'Night Emission')">Night Emission</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Obesity')" (change)="numberssaved($event, 'Obesity')">Obesity</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Orthopaedic problems')" (change)="numberssaved($event, 'Orthopaedic problems')">Orthopedic problems</mat-checkbox></div>

                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Poison ingestion')" (change)="numberssaved($event, 'Poison ingestion')">Poison ingestion</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Poor control over urination')" (change)="numberssaved($event, 'Poor control over urination')">Poor control over urination</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Pneumonia')" (change)="numberssaved($event, 'Pneumonia')">Pneumonia</mat-checkbox></div>
                    
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Prematurity')" (change)="numberssaved($event, 'Prematurity')">Prematurity</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Rheumatic fever')" (change)="numberssaved($event, 'Rheumatic fever')">Rheumatic fever</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Rheumatic fever')" (change)="numberssaved($event, 'Rubella')">Rubella</mat-checkbox></div>

                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Sexually transmitted infection')" (change)="numberssaved($event, 'Sexually transmitted infection')">Sexually transmitted infection</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Sickle cell anemia')" (change)="numberssaved($event, 'Sickle cell anemia')">Sickle cell anemia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Skin problems')" (change)="numberssaved($event, 'Skin problems')">Skin problems</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Speech/Language problems')" (change)="numberssaved($event, 'Speech/Language problems')">Speech/Language problems</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Thalassemia')" (change)="numberssaved($event, 'Thalassemia')">Thalassemia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Thyroid dysfunction')" (change)="numberssaved($event, 'Thyroid dysfunction')">Thyroid dysfunction</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Tonsilitis/Adenoiditis')" (change)="numberssaved($event, 'Tonsilitis/Adenoiditis')">Tonsilitis/Adenoiditis</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Tuberculosis')" (change)="numberssaved($event, 'Tuberculosis')">Tuberculosis</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Urinary tract infection')" (change)="numberssaved($event, 'Urinary tract infection')">Urinary tract infection</mat-checkbox></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Unconsciousness')" (change)="numberssaved($event, 'Unconsciousness')">Unconsciousness</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecked('Vision problems')" (change)="numberssaved($event, 'Vision problems')">Vision problems</mat-checkbox></div>
                      <div class="col-md-4">     <mat-checkbox [checked]="isCheckboxChecked('Vitamin deficiency')" (change)="numberssaved($event, 'Vitamin deficiency')">Vitamin deficiency</mat-checkbox>
                      </div>

                    </div>
                    <div *ngIf="showVitaminCheckboxes">
                      <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Vitamin_deficiency" (change)="onRadioChange($event)">
                      <mat-radio-button value="VitaminA">Vitamin A</mat-radio-button>
                      <mat-radio-button value="VitaminB">Vitamin B</mat-radio-button>
                      <mat-radio-button value="VitaminC">Vitamin C</mat-radio-button>
                      <mat-radio-button value="VitaminD">Vitamin D</mat-radio-button>
                      <mat-radio-button value="Other">Other</mat-radio-button>
                    </mat-radio-group>
                  
                    <div *ngIf="showTextBox" >
                      <mat-form-field appearance="outline" class="w-100 m-t-20 float-left">
                        <input matInput formControlName="Vitamin_deficiency_Other"  placeholder="Enter Other Information" />
                      </mat-form-field>
                    </div>
    
                    <div *ngIf="!showTextBox && stepForm.get('Vitamin_deficiency_Other').value">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput formControlName="Vitamin_deficiency_Other" placeholder="Enter Other Information" />
                      </mat-form-field>
                    </div>
                    </div>
                    
                  
                    

                  </section>
                  <mat-label class="formlabel">Mention Past Medication, if any</mat-label>
                      <mat-form-field appearance="outline" class="w-100" >
                      <input matInput placeholder="If any, type here" formControlName="Past_Medication">
                    </mat-form-field>
                </div>

                <div class="col-md-12">
                  <mat-label class="formlabel">Did the Student have any significant medical Issue currently? (Tick all that are applicable)</mat-label>
                  <mat-radio-group id="nradiobutton"  (change)="toggleCheckboxe($event)" formControlName="Does_the_student_have_any_medicalissue_Currently">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                  <section *ngIf="showCheckboxe">
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Does_the_student_have_any_medicalissue_Currently_Yes')" class="error-message">
                      Please provide details about  Did the Student have any significant medical Issue currently?
                    </mat-error>
                   
                    <div class="row">
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Acne vulgaris')" (change)="numberssave($event, 'Acne vulgaris')">Acne vulgaris</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Anemia')" (change)="numberssave($event, 'Anemia')">Anemia</mat-checkbox></div>
                      <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Appendicitis')" (change)="numberssave($event, 'Appendicitis')">Appendicitis</mat-checkbox></div>
                    </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Asthma')" (change)="numberssave($event, 'Asthma')">Asthma</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Attention/Learning disorders')" (change)="numberssave($event, 'Attention/Learning disorders')">Attention/Learning disorders</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Autism')" (change)="numberssave($event, 'Autism')">Autism</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Bleeding problems')" (change)="numberssave($event, 'Bleeding problems')">Bleeding problems</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Cancer')" (change)="numberssave($event, 'Cancer')">Cancer</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Cerebral palasy')" (change)="numberssave($event, 'Cerebral palasy')">Cerebral palsy</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Chicken pox')" (change)="numberssave($event, 'Chicken pox')">Chicken pox</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Covid')" (change)="numberssave($event, 'Covid')">Covid-19</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Cystic fibrosis')" (change)="numberssave($event, 'Cystic fibrosis')">Cystic fibrosis</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Dental problems')" (change)="numberssave($event, 'Dental problems')">Dental problems</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Ear infections')" (change)="numberssave($event, 'Ear infections')">Ear infections</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Epilepsy/Seizure/Convulsions')" (change)="numberssave($event, 'Epilepsy/Seizure/Convulsions')">Epilepsy/Seizure/Convulsions</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('G6PD deficiency')" (change)="numberssave($event, 'G6PD deficiency')">G6PD deficiency</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('German measles')" (change)="numberssave($event, 'German measles')">German measles</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Heart problems')" (change)="numberssave($event, 'Heart problems')">Heart problems</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Hearing problems')" (change)="numberssave($event, 'Hearing problems')">Hearing problems</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Hernia')" (change)="numberssave($event, 'Hernia')">Hernia</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('High blood pressure')" (change)="numberssave($event, 'High blood pressure')">High blood pressure</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Juvenile diabetes')" (change)="numberssave($event, 'Juvenile diabetes')">Juvenile diabetes</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Kidney problems')" (change)="numberssave($event, 'Kidney problems')">Kidney problems</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Learning disabilities')" (change)="numberssave($event, 'Learning disabilities')">Learning disabilities</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('HIV infection')" (change)="numberssave($event, 'HIV infection')">HIV infection</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Measles')" (change)="numberssave($event, 'Measles')">Measles</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Meningitis')" (change)="numberssave($event, 'Meningitis')">Meningitis</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Meningitis')" (change)="numberssave($event, 'Menstrual Disorder')">Menstrual Disorder</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Mental illness')" (change)="numberssave($event, 'Mental illness')">Mental illness</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Mumps')" (change)="numberssave($event, 'Mumps')">Mumps</mat-checkbox></div>

                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Mumps')" (change)="numberssave($event, 'Night Emission')">Night Emission</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Obesity')" (change)="numberssave($event, 'Obesity')">Obesity</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Orthopaedic problems')" (change)="numberssave($event, 'Orthopaedic problems')">Orthopedic problems</mat-checkbox></div>

                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Poison ingestion')" (change)="numberssave($event, 'Poison ingestion')">Poison ingestion</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Poor control over urination')" (change)="numberssave($event, 'Poor control over urination')">Poor control over urination</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Pneumonia')" (change)="numberssave($event, 'Pneumonia')">Pneumonia</mat-checkbox></div>
                   
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Prematurity')" (change)="numberssave($event, 'Prematurity')">Prematurity</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Rheumatic fever')" (change)="numberssave($event, 'Rheumatic fever')">Rheumatic fever</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Mumps')" (change)="numberssave($event, 'Rubella')">Rubella</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Sexually transmitted infection')" (change)="numberssave($event, 'Sexually transmitted infection')">Sexually transmitted infection</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Sickle cell anemia')" (change)="numberssave($event, 'Sickle cell anemia')">Sickle cell anemia</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Skin problems')" (change)="numberssave($event, 'Skin problems')">Skin problems</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Speech/Language problems')" (change)="numberssave($event, 'Speech/Language problems')">Speech/Language problems</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Thalassemia')" (change)="numberssave($event, 'Thalassemia')">Thalassemia</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Thyroid dysfunction')" (change)="numberssave($event, 'Thyroid dysfunction')">Thyroid dysfunction</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Tonsilitis/Adenoiditis')" (change)="numberssave($event, 'Tonsilitis/Adenoiditis')">Tonsilitis/Adenoiditis</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Tuberculosis')" (change)="numberssave($event, 'Tuberculosis')">Tuberculosis</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Urinary tract infection')" (change)="numberssave($event, 'Urinary tract infection')">Urinary tract infection</mat-checkbox></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Unconsciousness')" (change)="numberssave($event, 'Unconsciousness')">Unconsciousness</mat-checkbox></div>
                    <div class="col-md-4"> <mat-checkbox [checked]="isCheckboxChecke('Vision problems')" (change)="numberssave($event, 'Vision problems')">Vision problems</mat-checkbox></div>
                    <div class="col-md-4">     <mat-checkbox [checked]="isCheckboxChecked('Vitamin deficiency')" (change)="numberssave($event, 'Vitamin deficiency')">Vitamin deficiency</mat-checkbox></div>


                  </div>
                  <div *ngIf="showVitaminCheckboxes">
                    <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Currently_Vitamin_deficiency" (change)="oncurrentRadioChange($event)">
                    <mat-radio-button value="VitaminA">Vitamin A</mat-radio-button>
                    <mat-radio-button value="VitaminB">Vitamin B</mat-radio-button>
                    <mat-radio-button value="VitaminC">Vitamin C</mat-radio-button>
                    <mat-radio-button value="VitaminD">Vitamin D</mat-radio-button>
                    <mat-radio-button value="Other">Other</mat-radio-button>
                  </mat-radio-group>
                
                  <div *ngIf="showTextBox" >
                    <mat-form-field appearance="outline" class="w-100 m-t-20 float-left">
                      <input matInput formControlName="Currently_Vitamin_deficiency_Other"  placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
  
                  <div *ngIf="!showTextBox && stepForm.get('Currently_Vitamin_deficiency_Other').value">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Currently_Vitamin_deficiency_Other" placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
                  </div>
                  
                 
                </section>

                  <mat-label class="formlabel">Mention Current Medication, if any</mat-label>
                    <mat-form-field appearance="outline" class="w-100" >
                      <input matInput placeholder="If any, type here" formControlName="Current_Medication">
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label class="formlabel">Any known Allergies?</mat-label>
                  <mat-radio-group id="nradiobutton" formControlName="Any_Known_Allergies">
                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                    <mat-radio-button value="No">No</mat-radio-button>
                  </mat-radio-group>
                
                  <div *ngIf="stepForm.get('Any_Known_Allergies').value === 'Yes'">
                    <mat-label class="formlabel">If Yes, to what?</mat-label>

                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput placeholder="If any, type here" formControlName="Any_Known_Allergies_yes">                    </mat-form-field>
                    <mat-error *ngIf="nextButtonClicked && stepForm.get('Any_Known_Allergies_yes').hasError('required')" class="error-message">
                      Please provide details about your allergies
                    </mat-error>
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick()"></app-nextbutton>
              </div>
            </div>
          </form>
        </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
