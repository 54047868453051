<div class="row">
  <div class="col-md-12 p-r-32 p-l-32">
    <ng-container [formGroup]="stepForm">
      <div class="step-one boxshadow-form">
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->
        <div class="title text-center p-t-20 d-flex justify-content-center">
          <p class="marroncolor f-w-500 m-t-24 f-s-24 m-t-24 d-flex align-items-center">D. Emergency Contact</p>
          <label for="digitalsigninput" class="uploadedimages">
            <img class="uploadedimage" style="width: 100px;" *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl">
            <div *ngIf="!studentPhotoImageUrl">
            <img mat-card-image src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
            </div>
            </label>
        </div>
        <!--Header Block starts here Designed by Krishna Kanth on 18th Jan 2024-->

        <mat-card-content>
          <form [formGroup]="stepForm" (ngSubmit)="onSubmit()">
            <div id="scrollbar" class="internalform">
              <!--Primary Contact Details-->
              <div class="row m-t-40">
                <div class="col-md-12 m-t-40">
                  <div class="input-details">
                    <h1>Primary:</h1>

                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Contact<sup class="required-field"></sup></mat-label>
                  </div>

                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Primary_Contact_Belongs_To" (change)="PrimaryContactchange($event)">
                    <mat-radio-button value="Mother">Mother</mat-radio-button>
                    <mat-radio-button value="Father">Father</mat-radio-button>
                    <mat-radio-button value="Guardian">Guardian</mat-radio-button>
                    <mat-radio-button value="Other">Other</mat-radio-button>
                  </mat-radio-group>
                  
                  <div *ngIf="nextButtonClicked && stepForm.get('Primary_Contact_Belongs_To').value === 'Other' && !stepForm.get('Primary_Contact_Belongs_To_Other').value" class="error-message">
                    Primary Contact is required
                  </div>
  
                  <div *ngIf="PrimaryContact">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Primary_Contact_Belongs_To_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
  
                  <div *ngIf="!PrimaryContact && stepForm.get('Primary_Contact_Belongs_To_Other').value">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Primary_Contact_Belongs_To_Other" placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-6">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Contact Number<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Primary Contact Number" maxlength="15" formControlName="Primary_Contact" pattern="[0-9]*">
                  </mat-form-field>
                  <div class="error" *ngIf="stepForm.get('Primary_Contact').hasError('pattern')" class="error-message">
                    Please enter a valid mobile number (up to 15 digits).
                  </div>
                  <div *ngIf="nextButtonClicked && stepForm.get('Primary_Contact').hasError('required')" class="error-message">
                    Primary Contact Belongs to is required
                  </div>
                </div>

                <div class="col-md-6">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Full Name<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Primary Contact Full Name" formControlName="Primary_Contact_Full_Name">
                  </mat-form-field>
                </div>
              <!--Seconday Contact Details-->
              <!-- <h1>Seconday:</h1> -->

              <div class="col-md-12">
                <h1>Secondary:</h1>
                <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Contact<sup class="required-field"></sup></mat-label>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput placeholder="Secondary Contact Number" maxlength="15" formControlName="Secondary_Contact">
                </mat-form-field>
                <div class="error-message" *ngIf="stepForm.get('Secondary_Contact').hasError('pattern')">
                  Please enter a valid mobile number (up to 15 digits).
                </div>
               
              </div>
            </div>

              <div class="row" *ngIf="stepForm.get('Secondary_Contact').value">
              
                <div class="col-md-12">
                  <div class="input-details">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Secondary Contact<sup class="required-field"></sup></mat-label>
                  </div>
                  <mat-radio-group id="nradiobutton" aria-label="Select an option" formControlName="Secondary_Contact_Belongs_To" class="m-b-32" (change)="SecondayContactChange($event)">
                    <mat-radio-button value="Mother">Mother</mat-radio-button>
                    <mat-radio-button value="Father">Father</mat-radio-button>
                    <mat-radio-button value="Guardian">Guardian</mat-radio-button>
                    <mat-radio-button value="Other">Other</mat-radio-button>
                  </mat-radio-group>
                  <div *ngIf="nextButtonClicked && stepForm.get('Secondary_Contact_Belongs_To').value === 'Other' && !stepForm.get('Secondary_Contact_Belongs_To_Other').value" class="error-message">
                    Secondary Contact Belongs to Other is required
                  </div>
                  <div *ngIf="nextButtonClicked && stepForm.get('Secondary_Contact_Belongs_To').hasError('required')" class="error-message">
                    Secondary_Contact_Belongs_To is required
                  </div>
                  <div *ngIf="SecondayContact">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Secondary_Contact_Belongs_To_Other" [maxlength]="maxChars" placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
                  <div *ngIf="!SecondayContact && stepForm.get('Secondary_Contact_Belongs_To_Other').value">
                    <mat-form-field appearance="outline" class="w-100">
                      <input matInput formControlName="Secondary_Contact_Belongs_To_Other" placeholder="Enter Other Information" />
                    </mat-form-field>
                  </div>
                </div>
              
                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Full Name of Secondary Contact<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Primary Contact Full Name" formControlName="Secondary_Contact_Full_Name">
                  </mat-form-field>
                  <div *ngIf="nextButtonClicked && stepForm.get('Secondary_Contact_Full_Name').hasError('required')" class="error-message">
                    Full Name of Secondary Contact is required
                  </div>
                </div>
              </div>

              <!--Doctor Details-->
              <div class="row">
                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Family Physician Name<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Family Doctor Name" formControlName="Family_Doctor_Name">
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block formlabel">Physician Contact Number<sup class="required-field"></sup></mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Doctor Contact" maxlength="15" formControlName="Doctor_Contact_Number">
                  </mat-form-field>
                  <div class="error-message" *ngIf="stepForm.get('Doctor_Contact_Number').hasError('pattern')">
                    Please enter a valid mobile number (up to 15 digits).
                  </div>
                </div>
              </div>
            </div>

            <!--Buttons-->
            <div class="row justify-content-end m-t-32 float-right">
              <div class="col-lg-12 p-r-64">
                <app-previousbutton></app-previousbutton>
                <app-nextbutton (click)="onNextButtonClick()"></app-nextbutton>
              </div>
            </div>
          </form>
      </mat-card-content>
      </div>
    </ng-container>
  </div>
</div>
